<template>
  <div class="touchform">
    <v-card @click="close" class="close-icon" color="transparent">
      <i class="fas fa-times-circle"></i>
    </v-card>
    <v-form ref="form" @submit="onSubmit">
      <v-row>
      <v-col cols="12" md="6">
        <h3>GET IN TOUCH WITH US</h3>
        <v-row>
        <v-col cols="12" md="6" sm="12">
          <div>
            <v-text-field
                v-model="form.name"
                :rules="nameRules"
                class="custom-input-field"
                label="Full Name"
            ></v-text-field>
          </div>
        </v-col>

        <v-col cols="12" md="6" sm="12">
          <div>
            <v-text-field
                v-model="form.email"
                :rules="emailRules"
                class="custom-input-field"
                label="Email"
            ></v-text-field>
          </div>
        </v-col>


        <v-col cols="12" md="12" sm="12">
          <div>
            <v-text-field
                v-model="form.phoneNumber"
                :rules="phoneRules"
                class="custom-input-field"
                label="Phone Number"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <div>
            <v-textarea
                label="Message"
                v-model="form.message"
                :rules="messageRule"
                class="custom-input-field"
            >
            </v-textarea>
          </div>
        </v-col>

        <v-col md="12">
          <v-btn
              :loading="loading"
              class="subscribeBtn"
              type="submit"
              block
          >
            Submit
          </v-btn>
<!--          <button class="subscribeBtn" @click="close">-->
<!--            <v-progress-circular indeterminate></v-progress-circular>-->
<!--            Submit-->
<!--          </button>-->
        </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="align-items: center; justify-content: center; display: flex;"
      >
        <v-img
          aspect-ratio="16/9"
          cover
          class="plan-img"
          src="../../public/images/touch/plan.png"
        ></v-img>
      </v-col>
    </v-row>
    </v-form>
    <v-snackbar
        v-model="snackbar"
        rounded="pill"
        :timeout="3000"
    >
      Email sent successfully.
    </v-snackbar>
  </div>
</template>

<script>
// const querystring = require("querystring");

import axios from "axios";

export default {
  data() {
    return {

      snackbar:false,
      loading: false,
      phoneNumber: "",
      countryCodes: [
        { code: "+61", flag: "🇦🇺", name: "Australia" },
        { code: "+1", flag: "🇺🇸", name: "USA" },
        { code: "+44", flag: "🇬🇧", name: "UK" },
        // Add more country codes as needed
      ],
      selectedCountryCode: "+61", // Default country code
      isMailSent: false,
      form: {
        name: "",
        email: "",
        phoneNumber: "",
        message: ""
      },
      nameRules: [
        value => {
          if (value) return true

          return 'You must enter your name.'
        },
      ],
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail is required.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail must be valid.'
        },
      ],
      phoneRules: [
        value => {
          if (value) return true

          return 'Phone nunber is required.'
        },
      ],
      messageRule:[
        value => {
          if(value) return true;

          return "Message is required"
        }
      ]
    };
  },

  methods: {

    onSubmit(e){
      e.preventDefault();

      const isValid = this.$refs.form.validate();

      if(!isValid) return;

      const {
          name,
          phoneNumber,
          message,
          email
      } = this.form;

      this.loading = true;

      axios
          .post('http://candcprivatejets.com/mail.php',{name,
            phoneNumber,
            message,
            email
          })
          .then(response => {
            this.loading = false;
            this.snackbar = true;
            this.$refs.form.reset();
            this.close()
          }).catch((error) => {
            alert(error);
      });
    },

    handleInput(value) {
      console.log(value);
      // You can handle the input value here
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
