<template>
  <div class="grandprotection-container bg-waves">
    <div class="grandprotection">
      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public/images/hero-banner-butn.png" alt="Plane" /> -->
        <h4>GROUND TRANSPORT AND VIP PROTECTION SERVICES</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          SEAMLESS, SECURE, SOPHISTICATED
        </h1>
        <p class="white--text" style="margin-top: 2.5rem">
          At C&C Private Jets, our commitment to your exceptional travel
          experience extends beyond the skies. We provide premium ground
          transport and VIP protection services that guarantee your journey is
          seamless, secure, and handled with the utmost care from start to
          finish.
        </p>

        <button class="button" @click="dialog = true">Enquire Now</button>
      </div>
    </div>
    <div class="grandprotection-line">
      <v-img src="../../public/images/aircraft/plane.png"></v-img>
    </div>
    <div class="container-section">
      <div class="heading">
        <div class="eclipse"> </div>
        <h4>PREMIUM GROUND TRANSPORTATION</h4>
        <h2 class="mb-8">TRAVEL IN STYLE AND COMFORT</h2>
        <p>
          Our ground transportation services are designed to ensure a smooth transition from air to ground. Whether you're headed to the airport, a business meeting, or a special event, our fleet of luxury vehicles is at your disposal.</p>
      </div>
      <v-row justify="center" class="mt-12">
        <v-col class="col-mb-12 col-md-6 col-sm-12">
          <v-row>
            <v-col cols="12" class="horizontal-box">
              <div>
                <h2>LUXURY VEHICLES</h2>
                <p>
                   Choose from our selection of high-end sedans, limousines,
                  SUVs, and armored vehicles, all equipped with the latest
                  amenities for your comfort and security.
                </p>
              </div>
              <div>
                <v-img
                  src="../../public/images/luxury-vehicle.png"
                  max-width="200"
                ></v-img>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="horizontal-box">
              <div>
                <h2>CUSTOMIZED ROUTES</h2>
                <p>
                  We plan your travel routes meticulously, considering your
                  schedule, traffic patterns, and any personal preferences to
                  provide the most efficient and comfortable journey.
                </p>
              </div>
              <div>
                <v-img
                  src="../../public/images/grandprotection/route.png"
                  max-width="200" class="route-img"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="vertical-box col-mb-12 col-md-4 col-sm-12">
          <h2>PROFESSIONAL CHAUFFEURS</h2>
          <P
            >Our drivers are highly trained professionals who prioritize
            punctuality, discretion, and client service. They are well-versed in
            local routes and can navigate efficiently to ensure timely
            arrivals.</P
          >
          <div class="d-flex justify-center mt-2">
            <v-img
              src="../../public/images/grandprotection/meet.png"
              max-width="370px"
              max-height="270px"
              contain
            ></v-img>
          </div>
        </v-col>
      </v-row>
      <div class="heading" style="margin-top: 6rem">
        <h4>VIP PROTECTION SERVICES</h4>
        <h2 class="mb-8">ENSURING YOUR SAFETY WITH DISCRETION</h2>
        <p>
          Your safety is our priority. C&C Private Jets offers comprehensive VIP protection services tailored to the unique needs of high-profile individuals, families, and corporate groups.
        </p>
      </div>
      <v-row justify="center" class="mt-12">
        <v-col cols="4" class="vertical-box ml-0 mr-4 col-md-4 col-sm-12">
          <h2>EXPERIENCED</h2>
          <h2>SECURITY PERSONNEL</h2>
          <p>
            Our security team consists of experienced professionals with
            backgrounds in law enforcement and elite military units. They are
            trained to provide close protection, conduct risk assessments, and
            manage emergency situations discreetly and effectively.
          </p>
          <div class="d-flex justify-center mt-2">
            <v-img
              src="../../public/images/grandprotection/gaurd.png"
              max-width="370px"
              max-height="270px"
              contain
            ></v-img>
          </div>
        </v-col>
        <v-col cols="6" class="col-mb-12">
          <v-row>
            <v-col cols="12" class="horizontal-box">
              <div>
                <h2>TAILORED SECURITY</h2>
                <h2>PLANS</h2>
                <p>
                  We develop customized security strategies based on thorough
                  risk assessments, taking into account your specific security
                  needs, destination risks, and any other special requirements.
                </p>
              </div>
              <div>
                <v-img
                  src="../../public/images/grandprotection/lock.png"
                  max-width="200"
                ></v-img>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="horizontal-box">
              <div>
                <h2>24/7 COVERAGE</h2>
                <p>
                  Our protection services are available round the clock,
                  ensuring that you have constant security support throughout
                  your journey.
                </p>
              </div>
              <div>
                <v-img
                  src="../../public/images/grandprotection/computer.png"
                  max-width="200"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="heading" style="margin-top: 6rem">
        <h4>COORDINATED LOGISTICS</h4>
        <h2 class="mb-8">A HOLISTIC APPROACH TO YOUR TRAVEL NEEDS</h2>
        <p>
          To make your travel experience truly hassle-free, we coordinate all
          aspects of your ground services with your air
          travel arrangements. This holistic approach ensures that every segment
          of your journey is synchronized
        </p>
        <p>perfectly.</p>
      </div>
      <div class="horizontal-box left">
        <div>
          <h2>INTEGRATED</h2>
          <h2>SCHEDULING</h2>
          <p>
            Our logistics team works closely with our aviation and security
            experts to ensure that all ground and air movements are perfectly
            aligned with your itinerary.
          </p>
        </div>
        <div>
          <v-img
            src="../../public/images/grandprotection/white-plan.png"
            max-width="200"
          ></v-img>
        </div>
      </div>
      <div class="d-flex justify-end">
        <div class="horizontal-box right">
          <div>
            <h2>SINGLE POINT OF</h2>
            <h2>CONTACT</h2>
            <p>
              You will have a dedicated concierge who manages all aspects of
              your travel and security arrangements, available to assist you at
              any time.
            </p>
          </div>
          <div>
            <v-img
              src="../../public/images/grandprotection/checking.png"
              max-width="200"
            ></v-img>
          </div>
        </div>
      </div>
    </div>
    <div class="booking">
      <div class="imgcontainer">
<!--        <div class="blur-box">-->
<!--          &lt;!&ndash; <v-img src="../../public/images/grandprotection/blur.png"></v-img> &ndash;&gt;-->
<!--        </div>-->
        <div class="text-box">
          <h3>CONTACT US</h3>
          <h2>EXPERIENCE</h2>
          <h2>UNMATCHED SERVICE</h2>
          <h2>WITH C&C PRIVATE JETS</h2>
          <p>
            For more information on our ground transport and VIP protection
            services or to arrange your next journey, please contact us. Let C&C
            Private Jets take care of all your travel needs, ensuring a journey
            marked by luxury, efficiency, and peace of mind.
          </p>
          <button class="button mt-4" @click="dialog = true">Contact Now</button>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterComponent />
    <!------------Form dialog-------------------->

    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
  metaInfo () {
    return {
      title: `Ground Transport - C&C Private Jets`,
      meta: [
        {
          name: 'description',
          content:  "C&C Private Jets offers premium ground transport and VIP protection services to ensure a seamless, secure, and sophisticated travel experience. Enjoy exceptional care and convenience from start to finish."
        }
      ]
    }
  },
};
</script>

<style></style>
