<template>
  <div class="pp-container" style="background-image: url(images/superyacht/bg-waves.png); margin: 2rem">
    <!-- Navbar -->
    <HeaderComponent />
    <div class="pp-condition">
      <!-- Content -->
      <h1 style="margin-top: 10rem;">Privacy and Policy</h1>

      <h2>Introduction</h2>
      <p>C&C Private Jets Website Privacy Policy</p>
      <p>C&C Private Jets is committed to respecting your privacy and protecting your personal data (collectively referred to as “C&C Private Jets,” “we,” “us,” or “our” in this privacy policy). This privacy policy sets out how we collect, manage, and process your personal data, including any data you may provide through this website or via any other means (such as over the telephone) when you enter into a contract with us or otherwise provide personal data to us.</p>

      <h2>Controller</h2>
      <p>Please see below the relevant contact details of the data controller/processor. If you are uncertain or have a general question regarding data protection, please use the contact details for Australia. We have appointed a data protection officer (DPO) for each country or region where we store and process data. The DPO is responsible for overseeing questions concerning this privacy policy.</p>

      <h3>Australia (Controller)</h3>
      <ul>
        <li><strong>Legal entity:</strong> C&C Private Jets Pty Ltd</li>
        <li><strong>Full name:</strong> Data Protection Officer</li>
        <li><strong>Email address:</strong> <a href="mailto:privacy@candcprivatejets.com">privacy@candcprivatejets.com</a></li>
        <li><strong>Postal address:</strong> 7640 Fairway Boulevard, Hope Island, QLD 4212, Australia</li>
      </ul>
      <p>You have the right to make a complaint at any time to the Office of the Australian Information Commissioner (OAIC), the Australian regulator for data protection issues (<a href="https://www.oaic.gov.au" target="_blank">www.oaic.gov.au</a>). We would, however, appreciate the chance to deal with your concerns before you approach the OAIC, so please contact us in the first instance.</p>

      <h2>Changes to the Privacy Policy and Your Duty to Inform Us of Changes</h2>
      <p>We keep our privacy policy under regular review. This version was last updated on 4th March 2024. For any significant changes in the way personal information is treated or to the policy, we will make it clear on the website or directly via email so that you can review said changes. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>

      <h2>The Data We Collect About You</h2>
      <p>Personal data, or personal information, means any information from which a person can be identified. It does not include data where the identity has been removed (anonymous data). We may collect, use, store, and transfer different kinds of personal data about you, which we have grouped together as follows:</p>

      <ul>
        <li><strong>Identity data</strong> includes your first name, maiden name, last name, username or similar identifier, marital status, title, date of birth, gender, nationality, and passport information.</li>
        <li><strong>Contact data</strong> includes billing address, residential address, email address, and telephone numbers.</li>
        <li><strong>Financial data</strong> includes bank account and payment card details.</li>
        <li><strong>Transaction data</strong> includes details about payments to and from you and other details of services you have purchased from us.</li>
        <li><strong>Technical data</strong> includes internet protocol (IP) address, your browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
        <li><strong>Usage data</strong> includes information about how you use our website and services.</li>
        <li><strong>Sensitive data</strong> includes information such as details of allergies and medical conditions you have provided to us.</li>
      </ul>
      <p>This personal data may include information that you provide to us directly or through third parties or agents we work with, as well as information we collect when you use our products or services. Your personal data is used to provide you with the best service in respect of:</p>

      <ul>
        <li>Fulfilling your booking request and providing you with the required information for your travel arrangements with C&C Private Jets. This will include sharing your personal data with relevant authorities, charter providers, and other third parties to fulfill our obligations under any contract entered into between you and us for any provision of services.</li>
        <li>Dealing with general inquiries.</li>
        <li>Accounting and billing, immigration and customs control, health and safety, security, and legal compliance.</li>
        <li>Carrying out obligations and/or enforcing rights arising from any contracts entered into between you and us.</li>
        <li>Facilitating your service/travel arrangements, including all relevant third-party providers, airport operators, customs and immigration authorities, agents, or other service providers.</li>
        <li>Performing due diligence or other background checks to protect our rights, interests, property, and safety, as well as those of C&C Private Jets, its clients, and other relevant parties.</li>
        <li>Using your data for internal data analytics purposes to better understand our clients’ needs and preferences and to improve our services. We will always obtain your consent to perform such activities where required by law.</li>
        <li>General marketing purposes if you have given consent to receive information about us, our services, and our activities.</li>
      </ul>
      <p>Please note that in certain countries there is a requirement to give border control agencies and other public authorities access to booking and travel information and other personal data (including data obtained from official photo identification documents). Therefore, any information we hold about you and your travel arrangements may be disclosed to customs, immigration, and public authorities.</p>

      <h2>If You Fail to Provide Personal Data</h2>
      <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or services you have with us, but we will notify you if this is the case at the time.</p>

      <h2>Data Security & Storage</h2>
      <p>We are committed to ensuring your personal data is secure at all times. We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed. We limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know. They will only process your personal data on our instructions, and they are subject to a duty of confidentiality. Your personal data will be stored and processed in our systems and may also be stored on and processed by systems of a third-party data processor(s) appointed by us. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

      <h2>Retention of Your Data</h2>
      <p>We retain your personal information for as long as reasonably necessary to fulfill the purposes we collected it for. This includes providing any requested services or other requirements, including legal, regulatory, tax, accounting, or reporting. We review our retention periods for personal information on a regular basis. Please note we are legally required to hold specific information about our customers (including Contact, Identity, Financial, and Transaction Data) to fulfill our statutory obligations for six years after they cease being customers for tax purposes. If we have collected your personal data for marketing, we will keep it from the date you give your consent to receive marketing from us until you withdraw your consent. You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing messages sent to you. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a service purchase, services experience, or other transactions.</p>

      <h2>Legal Basis for Processing Personal Information</h2>
      <p>We will normally collect personal information from you only when:</p>
      <ul>
        <li>Responding to a booking form in order for us to contact you and effectively answer your query or request made through our website or online platforms.</li>
        <li>Entering into a contract with you directly or for one of your clients.</li>
        <li>Processing and delivering our services, including managing payments, fees, and charges, and collecting and/or recovering money owed to us.</li>
        <li>Administering and protecting our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting, and hosting of data).</li>
        <li>Understanding how our website is being used and being able to improve our user experience.</li>
        <li>Making suggestions and recommendations to you about services that may be of interest to you.</li>
      </ul>
      <p>If we collect and use your personal information for our legitimate interests (or those of any third party), this interest will normally be to operate our platform and communicate with you as necessary to provide our services to you. If you have any questions regarding the legal basis on which we collect your personal information, please contact our DPO.</p>

      <h2>Your Rights with Your Personal Data</h2>
      <p>The law gives you certain rights over the personal data we hold, including:</p>
      <ul>
        <li>Request access to your personal data.</li>
        <li>Request amendments to any incorrect data.</li>
        <li>Request deletion of your data if we no longer need it or do not have a legitimate reason to retain it.</li>
        <li>Request restriction of processing of your personal data.</li>
        <li>Right to withdraw consent where the use of your personal data is based on your consent.</li>
      </ul>
      <p>If you would like to exercise any of these rights, please contact our DPO.</p>

      <h2>Links to Third Party Websites</h2>
      <p>Our website and online services may include links to third-party websites of our partner networks/third-party websites. If you follow a link to any of these websites, please note they will have their own privacy notice and we recommend you review their policies. We do not accept any liability for third-party privacy policies and procedures or any personal data that may be collected through these websites or services.</p>

      <h2>Cookies</h2>
      <p>To help us provide you with a positive and personalized experience while using our website, we use cookies to help improve its functionality. Cookies are small text files containing information about your preferences and usage patterns, which allow the website to recognize you and provide a customized experience. The types of cookies we use on our website are:</p>
      <ul>
        <li><strong>Necessary cookies:</strong> essential for the proper functioning of our site.</li>
        <li><strong>Analytical/performance cookies:</strong> allow us to analyze site usage and improve our site’s performance.</li>
        <li><strong>Functionality cookies:</strong> remember your preferences and settings, allowing increased personalization.</li>
      </ul>
      <p>You can control and manage cookies through your browser settings as most browsers allow you to disable or block cookies. We may update our cookie policy for changes in the use of cookies or other operational, legal, or regulatory reasons. We would suggest you review this policy regularly to remain up to date with any changes.</p>

      <h2>Disclaimer</h2>
      <p>Please be aware that we make every effort to ensure our website, content, service, or feature of the site will be accurate and up to date. All information provided on the site is subject to changes without notice.</p>

      <h2>How to Contact Us</h2>
      <p>Please contact us if you have any questions about this privacy policy or the personal data we hold about you. Please contact our DPO at <a href="mailto:privacy@candcprivatejets.com">privacy@candcprivatejets.com</a>.</p>

    </div>
    <!-- Footer -->
    <FooterComponent />
    <!------------Form dialog-------------------->
  </div>
</template>

<script>
export default {
};
</script>