import Vue from "vue";
import VueRouter from "vue-router";
import Landingpage from "../views/landingpage.vue";
import Chartersservice from "../views/chartersservice.vue";
import Superyachtservices from "../views/superyachtservices.vue";

import Sustainability from "../views/sustainability.vue";
import Aircraft from "../views/aircraft.vue";
import Groupcharter from "../views/groupcharter.vue";
import Grandprotection from "../views/grandprotection.vue";
import Aboutus from "../views/aboutus.vue";
import Fleet from "../views/fleet.vue";
import Safety from "../views/safety.vue";
import Whyus from "../views/whyus.vue";
import Terms from "../views/termsandcondition.vue";
import Privacy from "../views/privacypolicy.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   redirect: "/home"
  // },
  {
    path: "/",
    component: Landingpage,
    // component: () => import("../views/landingpage.vue"),
  },
  {
    path: "/chartersservices",
    component: Chartersservice,
    // component: () => import("../views/chartersservice.vue"),
  },
  {
    path: "/superyachtservices",
    component: Superyachtservices,
    //component: () => import("../views/superyachtservices.vue"),
  },
  {
    path: "/sustainability",
    component: Sustainability,
    //component: () => import("../views/sustainability.vue"),
  },
  {
    path: "/aircraft",
    component: Aircraft,
    //component: () => import("../views/aircraft.vue"),
  },
  {
    path: "/groupcharter",
    component: Groupcharter,
    //component: () => import("../views/groupcharter.vue"),
  },
  {
    path: "/groundtransport",
    component: Grandprotection,
    //component: () => import("../views/grandprotection.vue"),
  },
  {
    path: "/aboutus",
    component: Aboutus,
    //component: () => import("../views/aboutus.vue"),
  },
  {
    path: "/fleet",
    component: Fleet,
    //component: () => import("../views/fleet.vue"),
  },
  {
    path: "/safety",
    component: Safety,
    //component: () => import("../views/safety.vue")
  },
  {
    path: "/whyus",
    component: Whyus,
    //component: () => import("../views/whyus.vue")
  },
  {
    path: "/terms",
    component: Terms,
    //component: () => import("../views/whyus.vue")
  },
  {
    path: "/privacy",
    component: Privacy,
    //component: () => import("../views/whyus.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      const element = document.querySelector(to.hash);
      if (element) {
        const offset = (window.innerHeight - element.offsetHeight) / 2; // Calculate offset for centering
        return window.scrollTo({
          top: element.offsetTop - offset,
          behavior: "smooth",
        });
      }
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
