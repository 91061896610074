<template>
  <div>
    <!-- top-section -->
    <div class="topsection">
      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public/images/hero-banner-butn.png" alt="Plane" /> -->

        <h1
          class="mt-7 mt- mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4e, #fed488);
            -webkit-background-clip: text;
            color: transparent;
            margin-top: 6rem !important;
          "
        >
          FLY SUSTAINABLY,
        </h1>
        <h1
          class="ma-0 pa-0 mb-5"
          style="
            background: linear-gradient(to right, #fbbd4e, #fed488);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          LAND SAFELY
        </h1>
        <p class="white--text">
          At C&C Private Jets, we are committed to providing an unparalleled
          luxury travel experience while prioritizing sustainability and safety.
          Our mission is to redefine the aviation industry by offering
          eco-friendly solutions without compromising on comfort or style.
        </p>
      </div>
      <!-- blur effect -->
      <div class="blureffect"></div>
    </div>
    <!-- trip-shedutle -->
    <div id="tripSheduleSection" class="trip-shedule">
      <div class="trip">
        <ThirdpartyformComponent />
      </div>
      <!-- <div class="box">
        <div class="trip">
          <div class="frame-26088220">
            <div>
              <div class="frame-26088219">
                <div
                  @click="onewaybtn()"
                  :class="!oneway ? 'button' : 'active-button'"
                >
                  <div class="inquire-now">One Way</div>
                </div>
                <div
                  :class="!roundtrip ? 'button' : 'active-button'"
                  @click="roundtripbtn()"
                >
                  <div class="inquire-now">Round Trip</div>
                </div>
                <div
                  @click="
                    () => {
                      roundtrip = false;
                      mulileg = true;
                      oneway = false;
                    }
                  "
                  :class="!mulileg ? 'button' : 'active-button'"
                >
                  <div class="inquire-now">Multi Leg</div>
                </div>
              </div>
              <div
                class="filterbox mt-5 mb-5"
                v-for="(filter, index) in filters"
                :key="index"
              >
                <div class="box1">
                  <div>
                    <div class="from">From</div>
                    <v-select
                      dense
                      flat
                      style="width: 38px"
                      background-color="#262626"
                      :items="states"
                      color="white"
                      :style="roundtrip ? 'width: 200px' : 'width: 100%'"
                      class="mt-2"
                      solo
                      v-model="filter.e1"
                      hide-details
                    ></v-select>

                    <div class="syd-sydney-airport mt-4">
                      SYD, Sydney Airport
                    </div>
                  </div>
                </div>

                <div class="box2">
                  <div>
                    <div class="to">To</div>
                    <v-select
                      dense
                      flat
                      background-color="#262626"
                      :items="states"
                      style="width: 38px"
                      color="white"
                      v-model="filter.e2"
                      :style="roundtrip ? 'width: 200px' : 'width: 100%'"
                      hide-details
                      class="mt-2"
                      solo
                    ></v-select>

                    <div class="mel-melbourne-airport mt-4">
                      MEL, Melbourne Airport
                    </div>
                  </div>
                </div>
                <div class="box3">
                  <div>
                    <div class="journey-date">Journey Date</div>
                    <v-menu
                      ref="menu2"
                      v-model="filter.menu2"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filter.date"
                          readonly
                          v-bind="attrs"
                          style="width: 38px"
                          class="mt-2"
                          background-color="#262626"
                          :style="roundtrip ? 'width: 200px' : 'width: 100%'"
                          hide-details
                          v-on="on"
                          solo
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filter.date"
                        no-title
                        scrollable
                        :min="minDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="filter.menu2 = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="filter.menu2 = false"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <div class="_2024-sunday mt-3">2024, Sunday</div>
                  </div>
                </div>
                <div v-if="roundtrip" class="box5">
                  <div>
                    <div class="journey-date">Journey Date</div>
                    <v-menu
                      ref="menu"
                      v-model="filter.menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filter.date1"
                          readonly
                          v-bind="attrs"
                          class="mt-2"
                          background-color="#262626"
                          :style="roundtrip ? 'width: 200px' : 'width: 100%'"
                          hide-details
                          v-on="on"
                          solo
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filter.date1"
                        no-title
                        scrollable
                        :min="minDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="filter.menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="filter.menu = false"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <div class="_2024-sunday mt-3">2024, Sunday</div>
                  </div>
                </div>

                <div
                  class="box4"
                  :style="roundtrip ? '  padding-left: 3rem;' : ''"
                >
                  <div :class="filters.length > 1 ? 'closebox' : ''">
                    <div v-if="!mulileg" class="button2">
                      <div class="inquire-now">Enquire Now</div>
                    </div>
                    <div v-if="mulileg" class="button2" @click="addFilter">
                      <div class="inquire-now">Add New Flight</div>
                    </div>
                    <div
                      v-if="filters.length > 1"
                      class="ml-4"
                      @click="removeFilter(index)"
                    >
                      <img
                        style="cursor: pointer; width: 37%; margin-top: 7%"
                        src="../../public/images/close.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="mulileg" class="button2">
                <div class="inquire-now">Enquire Now</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div>
      <carousal />
    </div>

    <!-- Services -->
    <div class="Services">
      <div class="plane">
        <v-img src="../../public/images/charters/Group.png"></v-img>
      </div>
      <div class="d-flex justify-center">
        <h2 style="text-transform: uppercase">Why Choose C&C Private Jets?</h2>
      </div>
      <div class="d-flex justify-center mt-11">
        <div>
          <div class="card">
            <div class="grid-container">
              <div class="grid-item image-container">
                <img
                  src="../../public/images/service1.png"
                  alt="Service Image"
                />
              </div>
              <div class="grid-item d-flex align-center text-container">
                <div class="pa-9 contant-card">
                  <h3 class="white--text">Sustainability First</h3>
                  <span>
                    Our commitment to sustainability sets us apart. We
                    continually invest in the latest green technologies and
                    practices to reduce our environmental impact, ensuring that
                    your journey is as eco-friendly as it is luxurious.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card my-14">
            <div class="grid-container-reverse">
              <div class="grid-item d-flex align-center text-container">
                <div class="pa-9 contant-card">
                  <h3 class="white--text">Experienced and Unique Team</h3>
                  <span>
                    With over 30 years of combined experience in aviation and
                    business, our team is dedicated to providing exceptional
                    service. Our experts are passionate about aviation and are
                    committed to exceeding your expectations.
                  </span>
                </div>
              </div>
              <div class="grid-item image-container">
                <img src="../../public/images/service2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="grid-container">
              <div class="grid-item image-container">
                <img src="../../public/images/service3.png" alt="" />
              </div>
              <div class="grid-item d-flex align-center text-container3">
                <div class="pa-9 contant-card">
                  <h3 class="white--text">
                    Customer Experience and Satisfaction
                  </h3>
                  <span>
                    At C&C Private Jets, your satisfaction is our top priority.
                    From the moment you book with us to the moment you arrive at
                    your destination, we are here to ensure a seamless and
                    memorable experience. Our customer-centric approach
                    guarantees personalized service tailored to your specific
                    needs.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact us -->
    <div class="contactus">
      <!-- blur effect -->
      <div class="blureffect"></div>
      <div class="d-flex justify-center">
        <h2 style="text-transform: uppercase">
          C&C Private Jets - Where luxury meets sustainability.
        </h2>
      </div>
      <div class="d-flex justify-center">
        <h2 style="text-transform: uppercase">
          Fly with us and experience the difference.
        </h2>
      </div>
      <img
        class="w-100"
        src="../../public/images/divider.png"
        style="margin: 8rem 0"
        alt=""
      />

      <div class="d-flex justify-center">
        <h2 style="text-transform: uppercase">Contact Us</h2>
      </div>

      <div class="d-flex justify-center span1">
        <span
          >Ready to take off with C&C Private Jets? Get in touch with us today
          to</span
        >
      </div>
      <div class="d-flex justify-center span1">
        <span
          >learn more about our services and how we can make your travel
        </span>
      </div>
      <div class="d-flex justify-center span1">
        <span> dreams a reality.</span>
      </div>
      <img
        class="w-100"
        src="../../public/images/divider.png"
        style="margin: 8rem 0"
        alt=""
      />
      <div class="d-flex justify-center">
        <h2 style="text-transform: uppercase">Stay Connected</h2>
      </div>

      <div class="d-flex justify-center span1">
        <span
          >Follow us on social media to stay updated with the latest news,
          offers,</span
        >
      </div>
      <div class="d-flex justify-center span1">
        <span>and innovations in sustainable luxury travel. </span>
      </div>
      <div class="d-flex justify-center mt-9 social-btn">

        <a target="_blank" href="https://www.instagram.com/ccprivatejets?igsh=dG54amx4djltYXZt">
          <img
            class="cursor-pointer mx-3"
            src="../../public/images/insta.svg"
            alt=""
          />
        </a>
        <a target="_blank" href="https://www.facebook.com/profile.php?id=61563399845518&mibextid=LQQJ4d">
          <img
            class="cursor-pointer mx-3"
            src="../../public/images/fbicon.svg"
            alt=""
          />
        </a>
<!--        <img-->
<!--          class="cursor-pointer mx-3"-->
<!--          src="../../public/images/tiktokicon.svg"-->
<!--          alt=""-->
<!--        />-->
        <a target="_blank" href="https://www.linkedin.com/company/c-c-private-jets/">
          <img
            class="cursor-pointer mx-3"
            src="../../public/images/linkedin.svg"
            alt=""
          />
        </a>
      </div>
      <img
        class="w-100"
        src="../../public/images/divider.png"
        style="margin: 8rem 0"
        alt=""
      />
      <div class="d-flex justify-center span1">
        <span>For bookings and inquiries, email us at</span>
      </div>
      <div class="d-flex justify-center flex-column">
        <h2 style="text-transform: uppercase">sales@candcprivatejets.com</h2>
        <h2 style="text-transform: uppercase">+61 452 289 115</h2>
      </div>
      <img
        class="w-100"
        src="../../public/images/divider.png"
        style="margin: 8rem 0"
        alt=""
      />
      <div class="d-flex justify-center">
        <h2 style="text-transform: uppercase">
          Fly Sustainably, Land Safely with
        </h2>
      </div>
      <div class="d-flex justify-center">
        <h2 style="text-transform: uppercase">C&C Private Jets.</h2>
      </div>
      <button
          class="button btn-contact-now"
          @click="dialog = true"
      >
        <span class="white--text">Contact Now</span>
      </button>
    </div>
    <!-- Footer -->
    <FooterComponent />
    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import carousal from "@/components/carousal.vue";
import animateplane from "@/components/animateplane.vue";
import ThirdpartyformComponent from "@/components/thirdpartyform.component.vue";
export default {
  components: { carousal, animateplane, ThirdpartyformComponent },
  data() {
    return {
      dialog:false,
      minDate: new Date().toISOString().substr(0, 10),
      oneway: true,
      roundtrip: false,
      mulileg: false,
      e1: "Florida",
      e2: "Texas",
      e3: null,
      e4: null,
      states: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,

      // states: ['State1', 'State2', 'State3'], // replace with actual states data
      filters: [
        {
          e1: "Alabama",
          e2: "Alabama",
          date: null,
          date1: null,
          menu2: false,
          menu: false,
        },
      ],
    };
  },

  mounted() {
    console.log("df", this.$router);
  },
  methods: {
    close(){
      this.dialog = false
    },
    addFilter() {
      this.filters.push({
        e1: null,
        e2: null,
        date: null,
        menu2: false,
        menu: false,
      });
    },
    removeFilter(index) {
      if (index >= 0) {
        this.filters.splice(index, 1);
      }
    },
    roundtripbtn() {
      this.roundtrip = true;
      this.mulileg = false;
      this.oneway = false;
      if (this.filters.length > 0) {
        this.filters = this.filters.slice(0, 1);
      }
    },
    onewaybtn() {
      this.oneway = true;
      this.roundtrip = false;
      this.mulileg = false;
      if (this.filters.length > 0) {
        this.filters = this.filters.slice(0, 1);
      }
    },
  },
  metaInfo () {
    return {
      title: `C&C Private Jets | Luxury Aviation with a Focus on Sustainability & Safety`,
      meta: [
        {
          name: 'description',
          content:  "C&C Private Jets offers a luxurious travel experience with a focus on sustainability and safety. Fly eco-friendly without compromising on comfort or style."
        }
      ]
    }
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 3vw !important;
}
</style>
