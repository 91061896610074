<template>
  <div class="aboutus bg-waves">
    <div class="charters">
      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public../../public/images/hero-banner-butn.png" alt="Plane" /> -->
        <h4>  About Us</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          Three Decades of Excellence
        </h1>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          in Aviation
        </h1>
        <p class="white--text" style="margin-top: 2.5rem">
          At C&C Private Jets, we recognize the environmental impacts of air
          travel and are earnestly committed to minimizing our footprint through
          responsible practices and realistic sustainability goals. We strive
          for transparency in our initiatives and continuous improvement in our
          efforts.
        </p>

        <!-- <button class="button">Enquire Now</button> -->
      </div>
    </div>
    <div class="plane" style="margin: -13% 0 0 0">
      <v-img src="../../public/images/Worldmap.png"></v-img>
    </div>
    <div>
      <div>
        <div class="Services">
          <div class="d-flex justify-center">
            <div class="section1">
              <div class="eclipse"></div>
              <v-row class="pa-1">
                <v-col cols="12" md="6" style="align-content: center">
                  <div>
                    <div class="heading1"> <b> Our Story</b></div>
                    <h1>
                      A Legacy of Leadership and Innovation
                    </h1>
                    <p class="desc mt-9">
                      Founded by aviation enthusiasts and seasoned business professionals, C&C Private Jets has grown from a passionate venture into a premier provider of private jet services. Our journey began with a vision to transform the private aviation sector by offering bespoke, customer-centric travel solutions. Today, we are proud to be at the forefront of the industry, continuously setting higher standards and expanding our services globally.
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="img-container">
                    <v-img src="../../public/images/aboutus/img1.png"></v-img>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="Services">
          <div class="d-flex justify-center">
            <div class="section1">
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <div class="img-container">
                    <v-img src="../../public/images/aboutus/img2.png"></v-img>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="align-content: center">
                  <div class="eclipse"></div>

                  <div>
                    <div class="heading1">Our Expertise</div>
                    <h1>
                      Deep Industry <br />
                      Knowledge
                    </h1>
                    <p class="desc mt-9">
                      Our team is comprised of experts with extensive
                      backgrounds in both business management and aviation. This
                      unique blend of skills and experience allows us to
                      understand and meet the sophisticated demands of our
                      clients. From operational logistics to customer service
                      excellence, our deep industry knowledge ensures a seamless
                      travel experience for every client.
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="Services mb-6">
          <div class="d-flex justify-center">
            <div class="section1">
              <v-img
                  style="background-size: contain"
                  src="../../public/images/aboutus/services3.png">
              </v-img>
            </div>
          </div>
        </div>
        <div class="Services">
          <div class="d-flex justify-center">
            <div class="section1">
              <div class="d-flex justify-center">
                <div class="heading1">Our Values</div>
              </div>
              <div class="d-flex justify-center">
                <h1>integrity, Innovation, and Excellence</h1>
              </div>
              <div class="d-flex justify-center mt-9">
                <p class="text-center">
                  These core values guide everything we do at C&C Private Jets. We uphold the highest standards of integrity in every interaction, innovate to stay ahead in the industry, and strive for excellence in every facet of our business.
                </p>
              </div>
              <div class="filterbox">
                <div class="box1" style="padding-top: 2%">
                  <div class="d-flex justify-center">
                    <div>
                      <v-img
                        src="../../public/images/aboutus/icon1.png"
                        contain
                        width="60"
                      ></v-img>
                    </div>
                  </div>
                  <div class="d-flex justify-center my-5">
                    <div class="heading1">Integrity</div>
                  </div>
                  <div class="d-flex justify-center" style="text-align: center">
                    <p>
                      We operate with transparency and fairness, building trust
                      with our clients and partners.
                    </p>
                  </div>
                </div>
                <div class="box2">
                  <div class="d-flex justify-center">
                    <div>
                      <v-img
                        src="../../public/images/aboutus/icon2.png"
                        contain
                        width="60"
                      ></v-img>
                    </div>
                  </div>
                  <div class="d-flex justify-center my-5">
                    <div class="heading1">Innovation</div>
                  </div>
                  <div class="d-flex justify-center" style="text-align: center">
                    <p>
                      We embrace technological advancements and new ideas to
                      improve our services and customer experiences.
                    </p>
                  </div>
                </div>
                <div class="box3">
                  <div class="d-flex justify-center">
                    <div>
                      <v-img
                        src="../../public/images/aboutus/icon3.png"
                        contain
                        width="60"
                      ></v-img>
                    </div>
                  </div>
                  <div class="d-flex justify-center my-5">
                    <div class="heading1">Excellence</div>
                  </div>
                  <div class="d-flex justify-center" style="text-align: center">
                    <p>
                      We pursue excellence relentlessly, ensuring that we
                      deliver only the best to our clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Services">
          <div class="d-flex justify-center">
            <div class="section1">
              <div class="eclipse"></div>
              <v-row class="pa-1">
                <v-col cols="12" md="5" style="align-content: center">
                  <div class="mr-12">
                    <div class="heading1">Our Future</div>
                    <h1>Looking Ahead</h1>
                    <v-img
                      class="mt-9"
                      src="../../public/images/aboutus/Line2.png"
                    ></v-img>
                    <p class="desc mt-9">
                      As we move forward, C&C Private Jets remains committed to
                      leading the industry with innovative solutions and
                      exemplary service. We continue to expand our fleet,
                      explore new destinations, and enhance our services to meet
                      the evolving needs of our clients.
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" md="7">
                  <div class="img-container">
                    <v-img src="../../public/images/aboutus/img3.png"></v-img>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="booking">
          <div class="imgcontainer">
<!--            <div class="blur-box"></div>-->
            <div class="text-box">
              <h3>CONNECT WITH US</h3>
              <h2>Join Our Journey</h2>
              <p>
                Discover the difference that three decades of aviation
                excellence can make on your next journey. Contact C&C Private
                Jets to learn more about our services or to arrange your next
                flight. Let us take you where you need to go, with the luxury
                and efficiency you deserve.
              </p>
              <button class="button mt-4" @click="dialog = true">
                Contact Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterComponent />

    <!------------Form dialog-------------------->

    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
  metaInfo () {
    return {
      title: `About Us - C&C Private Jets`,
      meta: [
        {
          name: 'description',
          content:  "At C&C Private Jets, we have three decades of excellence in aviation. We are committed to minimizing our environmental impact through responsible practices, transparency, and continuous improvement."
        }
      ]
    }
  },
};
</script>

<style></style>
