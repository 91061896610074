<template>
  <div class="terms-container" style="background-image: url(images/superyacht/bg-waves.png); margin: 2rem;">
    <!-- Navbar -->
    <HeaderComponent />
    <div class="terms-condition">
      <!-- Content -->

      <h1 style="margin-top: 10rem;">TERMS & CONDITIONS OF CARRIAGE</h1>

      <h2>C&C Private Jets as Charter Flight Broker and Agent of the Client</h2>

      <h3>1: Definitions and Interpretation</h3>
      <p>In these Terms & Conditions, “C&C Private Jets”, “we”, “us” or “our” means:</p>
      <ul>
        <li>C&C Private Jets, a company registered in Australia, with its registered head office at 7640 Fairway boulevard, Hope Island Queensland 4212.</li>
      </ul>
      <p>Our “website” refers to <a href="http://www.candcprivatejets.com">www.candcprivatejets.com</a>.</p>
      <p>These terms and conditions apply when you engage with us in booking our services for a private charter jet or any other services offered by C&C Private Jets and are part of your Charter Broker Agreement (this “Agreement”) and represent the terms and conditions of your Charter Contract.</p>
      <p>The following terms shall have the following meanings:</p>
      <ul>
        <li>“Additional Services” shall have the meaning ascribed to such term in Article 8 hereof.</li>
        <li>“Aircraft” shall mean any aircraft operated by the Carrier to perform the Flight(s).</li>
        <li>“Baggage”, which is equivalent to luggage, shall mean such articles, effects, and other personal property of a Passenger as are necessary or appropriate for wear, use, comfort, or convenience in connection with his trip. Unless otherwise specified, it shall include both checked and unchecked baggage of the Passenger.</li>
        <li>“Carriage Agreement” shall have the meaning ascribed to such term in Article 3 hereof.</li>
        <li>“Carrier” shall mean the air carrier being the operator of the Aircraft (other than C&C Private Jets), whose ICAO code is contained in the flight number(s) indicated in the Charter Contract.</li>
        <li>“Client” shall mean the legal entity or individual booking the Flight(s) either for his own account as Passenger, or for the account of third Passenger(s).</li>
        <li>“Client’s Agent” shall mean C&C Private Jets.</li>
        <li>“Flight(s)” shall mean the flight or series of flights operated by the Carrier to be performed from the point of departure until destination as described in the Charter Contract.</li>
        <li>“Charter Contract” shall mean the confirmation of the Flight(s) as may be issued from time to time to the Client by the Client’s Agent and signed by the Client.</li>
        <li>“Passenger” shall mean any person except members of the crew carried or to be carried in an Aircraft with the consent of the Carrier.</li>
        <li>“PNR Data” shall mean the passenger’s name record (PNR) data, including the Client’s name (as the person providing the information and/or making the booking) and the Passengers’ names, genders, addresses, dates and places of birth, passport details (nationality, number and date of expiry), contact phone numbers, the date of reservation, the travel itinerary, information concerning Baggage and any changes to the foregoing.</li>
        <li>“Price” shall mean the price specified in the Charter Contract.</li>
        <li>“Replacement Aircraft” shall have the meaning ascribed to such term in Article 10 hereof.</li>
        <li>“Replacement Flight” shall have the meaning ascribed to such term in Article 10 hereof.</li>
      </ul>
      <p>Where the context permits, the use of the singular herein shall include the plural and vice versa, and the use of the masculine personal pronoun shall include both genders.</p>
      <p>Unless otherwise provided, the term “or” is not exclusive and “include”, “including” and “in particular” are not limiting.</p>

      <h3>2: Scope of Application</h3>
      <p>This Agreement is entered into between the Client’s Agent and the Client.</p>
      <p>By executing the Charter Contract or by accepting this Agreement in any other manner (in particular by confirming the Flight(s), whether orally or in writing, and/or by paying the Price), the Client accepts that they and all Passengers are party to and comply with the terms of this Agreement.</p>
      <p>The Client shall not be entitled to assign this Agreement to any person without the Client’s Agent’s prior written consent.</p>
      <p>All requests to the Carrier to be made by the Client and/or the Passengers hereunder shall be made through the Client’s Agent.</p>
      <p>No variation of this Agreement shall be effective unless agreed in writing by the Client’s Agent.</p>
      <p>In case of contradiction between the terms of the Charter Contract and the terms of this Agreement, the terms of the Charter Contract shall prevail.</p>

      <h3>3: Appointment of the Client’s Agent</h3>
      <p>The Client hereby appoints the Client’s Agent as its agent for arranging the Flight(s) by chartering the Aircraft from the Carrier, it being understood that the Client’s Agent shall act in a capacity as agent of, for, and on behalf of, the Client. Accordingly, the Client authorises the Client’s Agent to enter into a carriage agreement (including any general terms and conditions of carriage) with the Carrier as agent of, for, and on behalf of, the Client (the “Carriage Agreement”).</p>

      <h3>4: Carrier’s Limitations</h3>
      <p>The Client and the Passengers acknowledge and agree to the following limitations:</p>
      <ul>
        <li>The pilot in command of the Aircraft will be in complete charge and control of the Aircraft at all times and shall have absolute discretion as to all matters relating to the operation of the Aircraft. In particular, if, in the pilot in command’s sole judgment, the safety of flight may be jeopardised, then the pilot in command may terminate or cancel a Flight.</li>
        <li>The Carrier may refuse carriage or onward carriage, or may cancel the reservation of any Passenger in circumstances such as, but not limited to, the following:
          <ul>
            <li>Such action is necessary for reasons of safety;</li>
            <li>Such action is necessary to prevent violation of any applicable laws, regulations, orders, demands, or travel requirements of any country to be flown from, into or over; or</li>
            <li>The conduct, age or mental or physical state of the Passenger is such as to:
              <ul>
                <li>Require special assistance from the Carrier which the Carrier is unable to provide;</li>
                <li>Cause discomfort or make himself objectionable to other Passengers; or</li>
                <li>Involve any hazard or risk to himself or to other persons or to property.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>All Flight(s) are subject to airport slots, take-off, overflight, landing, and parking permissions.</li>
        <li>The Carrier may have to change at any time any information regarding the Flight(s) which is provided in the Charter Contract. In particular:
          <ul>
            <li>The Carrier may change the handling agent indicated in the Charter Contract;</li>
            <li>The departure and arrival times may be modified by the Carrier, in particular because of airport slots, take-off, overflight, landing and parking permissions;</li>
            <li>The flight time is given for information only and may change in particular because of weather conditions and air traffic control orders;</li>
            <li>The routing may be adapted by the Carrier in particular because of weather conditions or other operating reasons and may include a diversion or a stop.</li>
          </ul>
        </li>
        <li>The Client’s Agent shall not bear any obligation or liability to the Client or the Passengers out of or in connection with the limitations set forth in this Article.</li>
      </ul>

      <h3>5: Client’s and Passengers’ Obligations and Compliance with Laws</h3>
      <p>The Client shall indicate to the Client’s Agent (for further transmission to the Carrier) the identity of all Passengers sufficiently in advance before the first Flight and shall communicate to the Client’s Agent (for further transmission to the Carrier) all information and documents (including passport copies) related to the Passengers as may be requested by the Carrier (through the Client’s Agent).</p>
      <p>The Passengers shall be solely responsible for complying, and shall comply, with all laws, regulations, orders, demands, and travel requirements of any country to be flown from, into, or over (including immigration and customs regulations) and with the Carrier’s rules and instructions pertaining thereto. In particular, the Passenger shall be solely responsible for carrying a valid passport, obtaining any visa where required, and ensuring the accuracy of any required document or information.</p>
      <p>The Client’s Agent shall bear no liability for the consequences to any Passenger resulting from his failure to comply with such laws, regulations, orders, demands, travel requirements, rules or instructions (in particular in respect of any Passenger’s failure to hold the required travel documents). The Client shall be billed for any additional costs (including surcharges, fees, fines, penalties, taxes, expenses, and any additional loss or damage) arising out of the Client’s and/or any Passenger’s failure to comply with the foregoing.</p>
      <p>The Client and the Passengers shall also comply with the following:</p>
      <ul>
        <li>The Passengers shall arrive at the check-in location designated in the Charter Contract at the time indicated in the Charter Contract.</li>
        <li>The Passengers shall carry only the permitted quantity of Baggage. The permitted quantity of Baggage is indicated in the Charter Contract. The Carrier may refuse carriage of any Baggage in excess of the permitted quantity. The permitted quantity of Baggage may depend on the type of aircraft and the number of Passengers.</li>
        <li>The Passengers shall comply with the rules and regulations in force at the airport and with the instructions given by the Carrier, the pilot in command, or any airport or governmental official (including any customs or immigration official).</li>
        <li>The Passengers shall avoid causing any nuisance or inconvenience to the Carrier, the Client’s Agent, or to other passengers (in particular, they shall avoid any act of misconduct or harassment and shall avoid smoking in areas where smoking is prohibited).</li>
      </ul>

      <h3>6: Price, Payment, and Invoicing</h3>
      <p>The Price for the Flight(s) shall be indicated in the Charter Contract and confirmed by the Client’s Agent.</p>
      <p>Payment of the Price shall be made in accordance with the terms of payment specified in the Charter Contract.</p>
      <p>In the absence of any specific terms of payment, the Client shall pay the Price in full upon the date of execution of the Charter Contract.</p>
      <p>Any additional services requested by the Client shall be invoiced separately and paid for by the Client in accordance with the terms of payment specified in the relevant invoice.</p>
      <p>If the Client fails to pay any sum due hereunder by the due date, the Client shall be liable to pay interest on the overdue amount from the due date until the date of actual payment at the rate of 8% per annum above the base rate of the National Bank of Australia from time to time in force.</p>

      <h3>7: Cancellation and Termination</h3>
      <p>If the Client cancels the Flight(s) for any reason whatsoever after the date of execution of the Charter Contract, the Client shall be liable to pay the cancellation charges set out in the Charter Contract.</p>
      <p>If the Charter Contract does not specify any cancellation charges, the Client shall be liable to pay 100% of the Price for the Flight(s) in case of cancellation within 30 days of the scheduled departure time of the first Flight, and 50% of the Price for the Flight(s) in case of cancellation more than 30 days before the scheduled departure time of the first Flight.</p>
      <p>The Client’s Agent may terminate this Agreement at any time by giving written notice to the Client if the Client commits any material breach of this Agreement and (if such breach is remediable) fails to remedy that breach within 14 days of the Client being notified in writing of the breach.</p>

      <h3>8: Additional Services</h3>
      <p>The Client’s Agent may, upon the Client’s request, arrange for additional services in connection with the Flight(s), such as ground transportation, catering, accommodation, and other related services (“Additional Services”).</p>
      <p>Any Additional Services shall be invoiced separately and paid for by the Client in accordance with the terms of payment specified in the relevant invoice.</p>
      <p>The Client’s Agent shall not be responsible for the provision of the Additional Services and shall bear no liability to the Client or the Passengers for any loss or damage arising out of or in connection with the provision of the Additional Services.</p>

      <h3>9: Liability</h3>
      <p>The Client’s Agent shall bear no liability to the Client or the Passengers for any loss or damage arising out of or in connection with the Flight(s), except in cases of gross negligence or willful misconduct.</p>
      <p>In no event shall the Client’s Agent be liable to the Client or the Passengers for any indirect, special, or consequential loss or damage, including loss of profit, loss of revenue, or loss of business opportunity.</p>
      <p>The Client shall indemnify and hold harmless the Client’s Agent from and against any and all claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys’ fees) arising out of or in connection with any breach of this Agreement by the Client or the Passengers.</p>

      <h3>10: Replacement Aircraft and Replacement Flight</h3>
      <p>If the Aircraft becomes unavailable for any reason whatsoever before or after the commencement of the Flight(s), the Client’s Agent may, but shall not be obligated to, arrange for a replacement aircraft to perform the Flight(s) (“Replacement Aircraft”).</p>
      <p>In case the Client’s Agent arranges for a Replacement Aircraft and if the Price of the Flight(s) is higher than the Price of the initial Aircraft, the Client shall pay the difference in price.</p>
      <p>In case the Client’s Agent arranges for a Replacement Aircraft and if the Price of the Flight(s) is lower than the Price of the initial Aircraft, the Client shall be entitled to a refund of the difference in price.</p>
      <p>If the Client’s Agent is unable to arrange for a Replacement Aircraft or if the Client does not accept the Replacement Aircraft, the Client’s Agent shall refund to the Client any part of the Price paid by the Client for the Flight(s) not performed (“Replacement Flight”), and such refund shall constitute full and final settlement of all claims of the Client against the Client’s Agent in connection with the non-performance of the Flight(s).</p>

      <h3>11: Force Majeure</h3>
      <p>The Client’s Agent shall not be liable for any failure or delay in the performance of its obligations under this Agreement arising out of or in connection with any event of force majeure, including but not limited to acts of God, war, terrorism, civil unrest, strikes, lockouts, fire, flood, earthquake, or any other cause beyond the reasonable control of the Client’s Agent.</p>

      <h3>12: Governing Law and Jurisdiction</h3>
      <p>This Agreement shall be governed by and construed in accordance with the laws of Queensland, Australia.</p>
      <p>The courts of Queensland, Australia shall have exclusive jurisdiction to settle any dispute arising out of or in connection with this Agreement.</p>

      <h3>13: Entire Agreement</h3>
      <p>This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior agreements, understandings, and arrangements, whether oral or written, relating to the subject matter hereof.</p>
      <p>No variation of this Agreement shall be effective unless agreed in writing by the Client’s Agent.</p>

      <h3>14: Severability</h3>
      <p>If any provision of this Agreement is found by any court or other competent authority to be invalid, illegal, or unenforceable, that provision or part-provision shall, to the extent required, be deemed deleted, and the validity and enforceability of the other provisions of this Agreement shall not be affected.</p>
    </div>
    <!-- Footer -->
    <FooterComponent />
    <!------------Form dialog-------------------->
  </div>
</template>

<script>
export default {
};
</script>