<template>
  <div class="carousal">
    <div class="d-flex header justify-space-between align-center">
      <h2 class="pl-2">EXPLORE OUR SERVICES</h2>
      <!-- <div class="d-flex navigation-box">
        <v-img
          src="../../public/images/carousal/pervious-arrow.png"
          max-width="66"
          contain
          class="mr-6"
          :class="{ 'cursor-pointer': clickCount >= 1 }"
          @click="previous"
        ></v-img>
        <v-img
          src="../../public/images/carousal/next-arrow.png"
          max-width="66"
          contain
          :class="{ 'cursor-pointer': moveNext <= maxWidth - screenWidth }"
          @click="next"
        ></v-img>
      </div> -->
    </div>
    <div style="overflow: hidden; width: 100%">
      <!-------------------------------- for slider -->
      <!-- <div
        class="d-flex mt-5 slider-container"
        :style="{ transform: `translateX(-${moveNext}px)` }"
      >
        <v-card
          v-for="(item, index) in itemList"
          :key="index"
          :to="item.route"
          max-width="355"
          class="mx-3"
        >
          <v-img :src="item.image" max-width="100%" contain></v-img>
          <div class="card-details">
            <v-img :src="item.imgIcon" max-width="40" contain></v-img>
            <v-card flat color="transparent" class="h-100">
              <h3 v-html="item.title"></h3>
              <p class="desc" v-html="item.desc"></p>
            </v-card>
          </div>
        </v-card>
      </div> -->

      <!--------------------- for cards -->
      <div class="mt-5 grid-container">
        <div
          v-for="(item, index) in itemList"
          :key="index"
          style="overflow: hidden"
        >
          <v-card width="auto" class="serviceCard" :to="item.route">
            <v-img :src="item.image" max-width="100%" contain></v-img>
            <div class="card-details">
              <v-img :src="item.imgIcon" max-width="40" contain></v-img>
              <v-card flat color="transparent" class="h-100">
                <h3 v-html="item.title"></h3>
                <p class="desc" v-html="item.desc"></p>
              </v-card>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [
        {
          image: "/images/carousal/image1.png",
          title: "SUSTAINABLE PRIVATE JET CHARTERS",
          imgIcon: "/images/carousal/plane.png",
          desc: "Experience the epitome of luxury with our private jet charters, designed with sustainability in mind. We offer a range of eco-friendly aircraft options both on demand or planned world tours that minimize carbon footprint while ensuring a smooth and enjoyable journey.",
          route: "/sustainability",
        },
        {
          image: "/images/carousal/image2.png",
          title: "GROUP AIRLINE CHARTERS",
          imgIcon: "/images/carousal/plane.png",
          desc: "For larger groups and corporate travel, our commercial airline charters provide the flexibility and convenience you need. We offer tailored solutions to suit any itinerary, ensuring timely and comfortable travel for your team.",
          route: "/groupcharter",
        },
        {
          image: "/images/carousal/image3.png",
          title: "AIRCRAFT SALES & MANAGEMENT",
          imgIcon: "/images/carousal/handshake.png",
          desc: "Thinking about owning your own aircraft? Want to sell or maintain your current aircraft? C&C Private Jets offers comprehensive aircraft sales services. Our team will guide you through the process, ensuring you find the perfect aircraft or aircraft buyer that meets your needs and preferences.",
          route: "/aircraft",
        },

        {
          image: "/images/carousal/helecoptor.png",
          title: "HELICOPTER CHARTERS",
          imgIcon: "/images/carousal/hileicon.png",
          desc: "Reach your destination swiftly and in style with our helicopter charters. Ideal for short-haul trips, our helicopters offer quick, efficient, and scenic travel options.",
          route: "/chartersservices",
        },
        {
          image: "/images/carousal/yart.png",
          title: "YACHT CHARTERS",
          imgIcon: "/images/carousal/boat.png",
          desc: "Expand your horizons beyond the skies with our exclusive yacht charters. Whether you're planning a serene coastal getaway or a grand oceanic adventure, our fleet of luxurious yachts is at your service.",
          route: "/superyachtservices",
        },
        {
          image: "/images/carousal/carjet.png",
          title: "GROUND TRANSPORT AND VIP PROTECTION ",
          imgIcon: "/images/carousal/car.png",
          desc: "Arrive in style with our luxury car rental and transfer services. Choose from a selection of high-end vehicles to complement your travel experience, ensuring comfort, safety and elegance from the moment you leave your door to your final destination.",
          route: "/groundtransport",
        },
      ],
      moveNext: 0,
      clickCount: 0,
      screenWidth: window.innerWidth,
      autoSlideInterval: null,
    };
  },
  computed: {
    maxWidth() {
      return this.itemList.length * (365 + 34);
    },
  },
  methods: {
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    previous() {
      if (this.clickCount > 0) {
        this.clickCount -= 1;
        this.moveNext = (365 + 34) * this.clickCount;
      }
    },
    next() {
      if (this.moveNext <= this.maxWidth - this.screenWidth) {
        this.clickCount += 1;
        this.moveNext = (365 + 34) * this.clickCount;
      }
    },
    // startAutoSlide() {
    //   this.autoSlideInterval = setInterval(() => {
    //     this.next();
    //     if (this.moveNext >= this.maxWidth - this.screenWidth) {
    //       this.moveNext = 0;
    //       this.clickCount = 0;
    //     }
    //   }, 1500); // Change the interval time (3000ms) as needed
    // },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval);
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateScreenWidth);
    //this.startAutoSlide();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth);
    this.stopAutoSlide();
  },
};
</script>

<style scoped>
.slider-container {
  transition: transform 0.5s ease-in-out;
}
.grid-container {
  gap: 4.8rem;
  display: grid;
  grid-template-columns: auto auto auto auto;
  @media screen and (max-width: 1550px) {
    gap: 4rem;
    grid-template-columns: auto auto auto;
  }
  @media screen and (max-width: 1440px) {
    gap: 6rem;
  }
  @media screen and (max-width: 1162px) {
    gap: 2rem;
  }
  @media screen and (max-width: 1024px) {
    gap: 4rem;
    grid-template-columns: auto auto;
  }
  @media screen and (max-width: 720px) {
    gap: 4rem;
    grid-template-columns: auto;
  }
  /* background-color: #2196f3;
  padding: 10px; */
}
</style>

<style lang="scss" scoped>
h2 {
  font-size: 3vw !important;
}
</style>
