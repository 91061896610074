<template>
  <div class="sustainability bg-waves">
    <div class="charters">
      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public../../public/images/hero-banner-butn.png" alt="Plane" /> -->
        <h4>Sustainability at C&C Private Jets</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          Our Genuine Commitment to
       
          Environmental Responsibility
        </h1>
        <p class="white--text" style="line-height: 2rem; margin-top: 2.5rem">
          At C&C Private Jets, we recognize the environmental impacts of air
          travel and are earnestly committed to minimizing
       
          our footprint through responsible practices and realistic
          sustainability goals. We strive for transparency in our initiatives
       and continuous improvement in our efforts.</p>
        <!-- <button class="button">Enquire Now</button> -->
      </div>
    </div>
    <div class="plane">
      <v-img src="../../public/images/charters/Group.png"></v-img>
    </div>
    <div>
      <div>
        <div class="Services">
          <div class="d-flex justify-center">
            <div class="section1">
              <div class="eclipse"></div>
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <div class="img-container">
                    <v-img
                      src="../../public/images/sustainability/img1.png"
                      lazy-src="../../public/images/sustainability/img1.png"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="align-content: center">
                  <div>
                    <div class="heading1">Sustainable Aviation Fuel (SAF)</div>
                    <h1>
                      Progressive Steps Toward <br />
                      Reducing Emissions
                    </h1>
                    <p class="desc mt-9">
                      We are actively integrating Sustainable Aviation Fuel
                      (SAF) into our operations, which is produced from
                      sustainable resources like waste oils and agricultural
                      residues. SAF has the potential to significantly reduce
                      lifecycle carbon emissions:
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pa-1" >
                <v-col cols="12" md="6">
                  <div class="box-left">
                    <v-row>
                      <v-col cols="2">
                        <div class="pt-3 logo-img">
                          <v-img
                            class=""
                            src="../../public/images/sustainability/logo1.png"
                            lazy-src="../../public/images/sustainability/logo1.png"
                          ></v-img></div
                      ></v-col>
                      <v-col cols="10">
                        <div>
                          <h2>Current Usage</h2>
                          <p class="desc2">
                            We currently blend SAF with conventional jet fuel,
                            targeting gradual increases in SAF usage as
                            availability and economic factors allow.
                          </p>
                        </div></v-col
                      >
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="box-left">
                    <v-row>
                      <v-col cols="2">
                        <div class="pt-3 logo-img">
                          <v-img
                            src="../../public/images/sustainability/logo2.png"
                          ></v-img></div
                      ></v-col>
                      <v-col cols="10">
                        <div>
                          <h2>Future Goals</h2>
                          <p class="desc2">
                            Our long-term objective is to increase SAF
                            utilization in line with industry advancements and
                            supply chain developments.
                          </p>
                        </div></v-col
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="Services">
          <div class="d-flex justify-center">
            <div class="section1">
              <div class="eclipse"></div>
              <v-row class="pa-1">
                <v-col cols="12" md="6" style="align-content: center">
                  <div>
                    <div class="heading1">Route Optimization</div>
                    <h1>Smart and Efficient Flight Planning</h1>
                    <p class="desc mt-9">
                      Efficiency in flight routing is a key component of our
                      strategy to reduce unnecessary fuel consumption. We employ
                      the latest technology to select the most efficient routes
                      and continuously refine our algorithms based on real-world
                      data and evolving practices.
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="img-container">
                    <v-img
                      src="../../public/images/sustainability/img2.png"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <div class="box-left">
                    <v-row>
                      <v-col cols="2">
                        <div class="pt-3 logo-img">
                          <v-img
                            class=""
                            src="../../public/images/sustainability/logo3.png"
                          ></v-img></div
                      ></v-col>
                      <v-col cols="10">
                        <div>
                          <h2>Technology and Expertise</h2>
                          <p class="desc2">
                            We utilize advanced navigation tools and expert
                            analysis to optimize each flight path, aiming to
                            reduce the flight duration and fuel usage.
                          </p>
                        </div></v-col
                      >
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="box-left">
                    <v-row>
                      <v-col cols="2">
                        <div class="pt-3 logo-img">
                          <v-img
                            src="../../public/images/sustainability/logo4.png"
                          ></v-img></div
                      ></v-col>
                      <v-col cols="10">
                        <div>
                          <h2>Ongoing Adjustments</h2>
                          <p class="desc2">
                            Flight paths are regularly evaluated for potential
                            efficiency gains, reflecting changes in variables
                            such as weather and air traffic.
                          </p>
                        </div></v-col
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="Services">
          <div class="d-flex justify-center">
            <div class="section1">
              <div class="eclipse"></div>

              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <div class="img-container">
                    <v-img
                      src="../../public/images/sustainability/img3.png"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="align-content: center">
                  <div>
                    <div class="heading1">Carbon Offset Program</div>
                    <h1>Real Impact, Transparent Outcomes</h1>
                    <p class="desc mt-9">
                      Recognizing that current technologies and practices cannot
                      eliminate all emissions, we offer a carbon offset program.
                      This program supports environmental projects that
                      verifiably reduce, avoid, or sequester carbon.
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <div class="box-left">
                    <v-row>
                      <v-col cols="2">
                        <div class="pt-3 logo-img">
                          <v-img
                            class=""
                            src="../../public/images/sustainability/logo5.png"
                          ></v-img></div
                      ></v-col>
                      <v-col cols="10">
                        <div>
                          <h2>Carefully Selected Projects</h2>
                          <p class="desc2">
                            Each project is chosen based on stringent criteria
                            to ensure it delivers genuine environmental benefits
                            and contributes to sustainable development.
                          </p>
                        </div></v-col
                      >
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="box-left">
                    <v-row>
                      <v-col cols="2">
                        <div class="pt-3 logo-img">
                          <v-img
                            src="../../public/images/sustainability/logo6.png"
                          ></v-img></div
                      ></v-col>
                      <v-col cols="10">
                        <div>
                          <h2>Client Involvement</h2>
                          <p class="desc2">
                            We provide our clients with clear information about
                            how offsets are calculated and where contributions
                            go, allowing for informed decisions.
                          </p>
                        </div></v-col
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="Services">
          <div class="d-flex justify-center">
            <div class="section1">
              <div class="eclipse"></div>

              <v-row class="pa-1">
                <v-col cols="12" md="6" style="align-content: center">
                  <div>
                    <div class="heading1">Beyond Flying</div>
                    <h1>Sustainable Operations On the Ground</h1>
                    <p class="desc mt-9">
                      Our commitment to sustainability extends into our ground
                      operations and corporate practices.
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="img-container">
                    <v-img
                      src="../../public/images/sustainability/img4.png"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <div class="box-left">
                    <v-row>
                      <v-col cols="2">
                        <div class="pt-3 logo-img">
                          <v-img
                            class=""
                            src="../../public/images/sustainability/logo7.png"
                          ></v-img></div
                      ></v-col>
                      <v-col cols="10">
                        <div>
                          <h2>Waste Reduction</h2>
                          <p class="desc2">
                            We focus on reducing waste in our catering and
                            onboard services, opting for biodegradable and
                            recyclable materials whenever possible.
                          </p>
                        </div></v-col
                      >
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="box-left">
                    <v-row>
                      <v-col cols="2">
                        <div class="pt-3 logo-img">
                          <v-img
                            src="../../public/images/sustainability/logo8.png"
                          ></v-img></div
                      ></v-col>
                      <v-col cols="10">
                        <div>
                          <h2>Energy Efficiency</h2>
                          <p class="desc2">
                            Our facilities are designed to minimize energy
                            consumption, incorporating sustainable building
                            practices and technologies.
                          </p>
                        </div></v-col
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="booking">
          <div class="imgcontainer">
<!--            <div class="blur-box">-->
<!--              &lt;!&ndash; <v-img-->
<!--                src="../../public/images/sustainability/dull-yacht.png"-->
<!--                height="398"-->
<!--              ></v-img> &ndash;&gt;-->
<!--            </div>-->
            <div class="text-box">
              <h2>Engage with Us</h2>
              <p>
                We invite our clients and stakeholders to engage with us in our
                sustainability journey. By working together, we can make
                informed and effective contributions to environmental
                stewardship. For more information on our efforts or to discuss
                how you can be involved, please contact us
              </p>
              <button class="button mt-4" @click="dialog = true">
                Contact Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterComponent />
    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
  metaInfo () {
    return {
      title: `Sustainability - C&C Private Jets`,
      meta: [
        {
          name: 'description',
          content:  "At C&C Private Jets, we are committed to minimizing our environmental impact through responsible practices and sustainability goals. Discover our transparent and continuous efforts to enhance our eco-friendly initiatives."
        }
      ]
    }
  },
};
</script>

<style></style>
