<template>
  <div class="aircraft-container bg-waves">
    <div class="aircraft">
      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public/images/hero-banner-butn.png" alt="Plane" /> -->
        <h4>AIRCRAFT SALES AND MANAGEMENT</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          EXPERTISE YOU CAN TRUST
        </h1>
        <p class="white--text" style="margin-top: 2.5rem">
          At C&C Private Jets, we offer comprehensive aircraft sales and
          management services tailored to the unique needs of private jet owners
          and prospective buyers. With our extensive industry knowledge and
          dedicated support, we ensure a seamless experience whether you are
          purchasing, selling, or managing your private aircraft.
        </p>
       
        <button class="button" @click="dialog = true">Enquire Now</button>
      </div>
    </div>
    <div class="aircraft-line">
      <v-img src="../../public/images/aircraft/plane.png"></v-img>
    </div>
    <div class="heading">
      <div class="eclipse"></div>
      <h4>AIRCRAFT SALES</h4>
      <h2 class="mb-8">YOUR PERFECT JET AWAITS</h2>
      <p>
        Whether you are entering the world of private aviation or looking to
        upgrade or diversify your fleet, our sales
      </p>
      <p>experts are here to guide you through every step of the process.</p>
    </div>
    <v-row class="sales">
      <v-col class="sales-container1" cols="3">
        <div>
          <v-img
            src="../../public/images/aircraft/image1.png"
            max-width="98"
          ></v-img>
        </div>
        <h4>PERSONALIZED CONSULTATION</h4>
        <p>
          Our team works closely with you to understand your needs, preferences,
          and budget, ensuring we find the perfect aircraft to match your
          lifestyle.
        </p>
      </v-col>
      <v-col class="sales-container2" cols="3">
        <div>
          <v-img
            src="../../public/images/aircraft/image2.png"
            max-width="98"
          ></v-img>
        </div>
        <h4>WIDE COLLECTION</h4>
        <p>
          We offer a diverse range of aircraft, from light jets for short trips
          to large, luxurious airliners for international travel.
        </p>
      </v-col>
      <v-col class="sales-container3" cols="3">
        <div>
          <v-img
            src="../../public/images/aircraft/image3.png"
            max-width="98"
          ></v-img>
        </div>
        <h4>TRANSPARENT TRANSACTIONS</h4>
        <p>
          We ensure a transparent purchasing process, with clear communication
          and no hidden costs.
        </p>
      </v-col>
    </v-row>
    <div class="heading" style="margin-top: 8rem">
      <h4>AIRCRAFT MANAGEMENT</h4>
      <h2 class="mb-8">MAXIMIZING YOUR INVESTMENT</h2>
      <p>
        Owning a private jet should be a pleasure, not a burden. Our management
        services are designed to give you peace
      </p>
      <p>
        of mind, knowing that every aspect of your aircraft’s operation is being
        expertly handled.
      </p>
    </div>
    <div class="d-flex justify-center">
      <div class="investments">
        <div class="investments-container">
          <div class="content">
            <h2>OPERATIONAL MANAGEMENT</h2>
            <div>
              <v-img src="../../public/images/aircraft/yellow-line.png"></v-img>
            </div>
            <p>
              We take care of all operational aspects, including crew
              management, maintenance scheduling, and regulatory compliance.
            </p>
          </div>
          <div>
            <v-img
              src="../../public/images/aircraft/investment1.png"
              max-width="500"
            ></v-img>
          </div>
        </div>
        <div class="investments-container">
          <div>
            <v-img
              src="../../public/images/aircraft/investment2.png"
              max-width="500"
            ></v-img>
          </div>
          <div class="content">
            <h2>FINANCIAL MANAGEMENT</h2>
            <div>
              <v-img src="../../public/images/aircraft/yellow-line.png"></v-img>
            </div>
            <p>
              Our team manages all financial aspects from budgeting to expense
              tracking and reporting, ensuring cost-effective operation.
            </p>
          </div>
        </div>
        <div class="investments-container bg-waves">
          <div class="content">
            <div class="eclipse" style=""></div>
            <h2>24/7 SUPPORT</h2>
            <div>
              <v-img src="../../public/images/aircraft/yellow-line.png"></v-img>
            </div>
            <p>
              Our dedicated support team is available around the clock to assist
              with any needs that arise, ensuring your aircraft is ready to fly
              whenever you are.
            </p>
          </div>
          <div>
            <v-img
              src="../../public/images/aircraft/investment3.png"
              max-width="500"
            ></v-img>
          </div>
        </div>
      </div>
    </div>
    <div class="heading" style="margin-top: 4rem">
      <h4>WHY CHOOSE C&C PRIVATE JETS?</h4>
      <h2 class="mb-8">DEDICATED EXPERTISE AND TAILORED SERVICES</h2>
      <p>
        Owning a private jet should be a pleasure, not a burden. Our management
        services are designed to give you peace
      </p>
      <p>
        of mind, knowing that every aspect of your aircraft’s operation is being
        expertly handled.
      </p>
    </div>
    <div class="slider">
      <carousel-3d
        :perspective="0"
        :space="500"
        :display="3"
        :width="700"
        :autoplay="true"
        :autoplay-timeout="3000"
        :height="700"
        @after-slide-change="handleSlideChange"
      >
        <slide :index="0">
          <div @click="handleIndex('0')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/aircraft/simage2.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>
              <div class="side-content" v-if="activeIndex === 0">
                <div>
                  <h3>Experienced</h3>
                  <h3>Professionals</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  Our team comprises industry veterans with extensive knowledge
                  in both aircraft sales and management.
                </p>
              </div>
            </div>
          </div>
        </slide>
        <slide :index="1">
          <div @click="handleIndex('1')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/aircraft/simage3.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>
              <div class="side-content" v-if="activeIndex === 1">
                <div>
                  <h3>Customized</h3>
                  <h3>Solutions</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  We provide customized solutions that cater to the specific
                  needs of each client, ensuring optimal care and efficiency.
                </p>
              </div>
            </div>
          </div>
        </slide>
        <slide :index="2">
          <div @click="handleIndex('2')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/aircraft/simage1.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>

              <div class="side-content" v-if="activeIndex === 2">
                <div>
                  <h3>Client-Centric</h3>
                  <h3>Approach</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  We prioritize your needs and satisfaction, striving to exceed
                  expectations in every interaction.
                </p>
              </div>
            </div>
          </div>
        </slide>
      </carousel-3d>
    </div>
    <div class="booking">
      <div class="imgcontainer">
<!--        <div class="blur-box"></div>-->
        <div class="text-box">
          <h3>Contact Us</h3>
          <h2>
            Discover How We <br />
            Can Serve You
          </h2>
          <p>
            Interested in buying or selling a private jet, or in need of
            management services? Contact our Aircraft Sales and Management team
            today. We are here to assist you with expert advice and tailored
            services designed to meet your aviation needs.
          </p>
          <button class="button mt-4" @click="dialog = true">Contact Now</button>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterComponent />
    <!------------Form dialog-------------------->

    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      activeIndex: 0,
      dialog: false,
    };
  },
  methods: {
    handleSlideChange(event) {
      //console.log(event); // Log the entire event object to inspect it
      this.activeIndex = +event.index || +event.slideIndex || +event; // Try different ways to access the index
      //console.log("Active slide index:", +this.activeIndex);
    },
    handleIndex(index) {
      //console.log(index);
    },
    close() {
      this.dialog = false;
    },
  },
  metaInfo () {
    return {
      title: `Aircraft - C&C Private Jets`,
      meta: [
        {
          name: 'description',
          content:  "C&C Private Jets offers expert aircraft sales and management services, tailored to the unique needs of private jet owners and buyers. Trust our industry knowledge for a seamless experience."
        }
      ]
    }
  },
};
</script>

<style></style>
