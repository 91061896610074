<template>

  <div class="w-100" style="z-index: 999999; position: fixed">
    <!----------------Desktop header---------------->
    <div class="desktopHeader">
      <div style="z-index: 2" class="navbar">
        <div class="nav-links">
          <!-- <router-link to="/home" active-class="active-p">
            <p>Home</p>
          </router-link> -->
          <a href="/"> <p>Home</p></a>
          <router-link to="/aboutus" active-class="active-p">
            <p>About us</p>
          </router-link>
          <router-link to="/whyus" active-class="active-p">
            <p>Why us</p>
          </router-link>
          <router-link to="/fleet" active-class="active-p">
            <p>Fleet</p>
          </router-link>
          <router-link to="/safety" active-class="active-p">
            <p>Safety</p>
          </router-link>
        </div>

        <div class="cta">
          <router-link to="/sustainability" active-class="active-p">
            <p>Sustainability</p>
          </router-link>
          <nav>
            <ul class="menu pa-0 ma-0">
              <li style="position: relative">
                <p>Additional Services</p>
                <ul
                  class="sub-menu"
                >
                  <router-link to="/groupcharter" active-class="active-p">
                    <li>GROUP AIRLINE CHARTERS</li>
                  </router-link>
                  <router-link to="/aircraft" active-class="active-p">
                    <li>AIRCRAFT SALES & MANAGEMENT</li>
                  </router-link>
                  <router-link to="/chartersservices" active-class="active-p">
                    <li>HELICOPTER CHARTERS</li>
                  </router-link>
                  <router-link to="/superyachtservices" active-class="active-p">
                    <li>YACHT CHARTERS</li>
                  </router-link>
                  <router-link to="/groundtransport" active-class="active-p">
                    <li>GROUND TRANSPORT AND VIP PROTECTION</li>
                  </router-link>
                </ul>
              </li>
            </ul>
          </nav>

          <!-- <v-card
            :to="{ path: '/', hash: '#tripSheduleSection' }"
            flat
            class="button"
            ><span class="white--text"> Book flight</span></v-card
          > -->
          <a href="/#tripSheduleSection" class="button"
            ><span class="white--text">Request Quote</span></a
          >
        </div>

        <!-- <v-card flat to="/" color="transparent" class="img">
          <img
            src="../../public/images/logo.png"
            class="cursor-pointer"
            alt="Logo"
            style="max-width: 128px"
          />
        </v-card> -->
        <a href="/" class="img">
          <img
            src="../../public/images/logo.png"
            class="cursor-pointer"
            alt="Logo"
            style="max-width: 128px"
        /></a>
      </div>
    </div>
    <!----------------Mobile header---------------->
    <div class="mobileHeader">
      <div
        style="z-index: 2"
        class="navbar"
        :style="
          menuVisible
            ? `
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0%) 0%, rgba(0, 0, 0, 100%) 100%);
      min-height: calc(100vh + 25vh);
      -webkit-backdrop-filter: blur(31px);
      backdrop-filter: blur(31px);
      justify-content: unset;
      align-items: flex-start;  overflow: auto;
    `
            : ''
        "
      >
        <div
          class="top"
          :style="
            menuVisible
              ? '  width: 100%; border-bottom: 2px solid rgb(175 119 18 / 96%)'
              : ''
          "
        >
          <v-btn
            color="transparent"
            depressed
            class="navbar-icon"
            :style="!menuVisible ? 'top: 26%' : ''"
            @click="
              () => {
                this.menuVisible = !this.menuVisible;
              }
            "
          >
            <i class="fas fa-bars"></i
          ></v-btn>

          <v-card
            flat
            color="transparent"
            class="img"
            to="/"
            :style="!menuVisible ? 'top: 3rem' : ''"
          >
            <img
              src="../../public/images/logo.png"
              class="cursor-pointer"
              alt="Logo"
              style="max-width: 100px"
            />
          </v-card>
        </div>

        <transition name="slide">
          <div v-show="menuVisible" class="w-100 h-100 bottom">
            <div class="nav-links">
              <router-link to="/home" active-class="active-p">
                <p>Home</p>
              </router-link>
              <router-link to="/aboutus" active-class="active-p">
                <p>About us</p>
              </router-link>
              <router-link to="/whyus" active-class="active-p">
                <p>Why us</p>
              </router-link>
              <router-link to="/fleet" active-class="active-p">
                <p>Fleet</p>
              </router-link>
              <router-link to="/safety" active-class="active-p">
                <p>Safety</p>
              </router-link>
              <router-link to="/sustainability" active-class="active-p">
                <p>Sustainability</p>
              </router-link>
              <nav style="width: 15rem">
                <ul class="menu pa-0 ma-0">
                  <li style="position: relative">
Additional Services
                    <ul
                      class="sub-menu"
                    >
                      <router-link to="/groupcharter" active-class="active-p">
                        <li>GROUP AIRLINE CHARTERS</li>
                      </router-link>
                      <router-link to="/aircraft" active-class="active-p">
                        <li>AIRCRAFT SALES & MANAGEMENT</li>
                      </router-link>
                      <router-link
                        to="/chartersservices"
                        active-class="active-p"
                      >
                        <li>HELICOPTER CHARTERS</li>
                      </router-link>
                      <router-link
                        to="/superyachtservices"
                        active-class="active-p"
                      >
                        <li>YACHT CHARTERS</li>
                      </router-link>
                      <router-link
                        to="/grandprotection"
                        active-class="active-p"
                      >
                        <li>GROUND TRANSPORT AND VIP PROTECTION</li>
                      </router-link>
                    </ul>
                  </li>
                </ul>
              </nav>

              <v-card to="/" flat class="button">
                <span class="white--text">Request Quote</span>
              </v-card>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuVisible: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      console.log("Sdfdsf");
    },
  },
};
</script>

<style lang="scss">
* {
  list-style-type: none;
  box-sizing: border-box;
}

nav {
  list-style-type: none;
  box-sizing: border-box;
  text-align: center;
  margin-top: 2%;
}
/* Slide transition styles */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
.menu {
  display: inline-block;
}

.menu > li {
  float: left;
  color: white;
  width: auto;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-size: 17px;
}

.sub-menu {
  transform: scale(0);
  transform-origin: top center;
  transition: all 300ms ease-in-out;
  left: -5.4rem;
  position: absolute;
  width: 15rem;
  margin: 0;
  margin-left: 45px;
  padding: 0;
  top: 3rem;
  z-index: 1;
}

.sub-menu li {
  font-size: 12px;
  background: rgba(0, 0, 0, 0.87);
  padding: 8px 0;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  /*display: none;*/
  transform: scale(0);
  transform-origin: top center;
  transition: all 300ms ease-in-out;
}

.sub-menu li:last-child {
  border-bottom: 0;
}

.sub-menu li:hover {
  background: black;
}

.menu > li:hover .sub-menu li {
  /*display: block;*/
  transform: scale(1);
}

.menu > li:hover .sub-menu {
  transform: scale(1);
}
.desktopHeader {
  display: block;
  @media screen and (max-width: 1210px) {
    display: none;
  }
}
.mobileHeader {
  display: none;
  @media screen and (max-width: 1210px) {
    display: block;
  }
  .navbar-icon {
    font-size: 1.5rem;
    color: white;
    position: absolute;
    top: 5%;
    left: 3%;
  }
  .navbar {
    display: block;
    padding: 0 !important;
    // background: linear-gradient(
    //   to bottom,
    //   rgba(0, 0, 0, 0%) 0%,
    //   rgba(0, 0, 0, 100%) 100%
    // );
    //height: 100vh;

    .top {
      height: 14%;
    }
    // .bottom {
    //   height: 56vh;
    // }

    .button {
      width: 210px;
      border: 1px solid #fcc562;
      padding: 0.2px;
      margin-left: 1rem;
      height: 36px;
      border-radius: 120px;
      text-align: center;
      background: transparent !important;
      &:hover {
        background: #fcc562 !important;
        color: black;
        text-decoration: none;
      }
    }
    .img {
      left: auto;
      right: -2%;
      top: 7%;
    }
    .nav-links {
      display: inline-grid;
    }
  }
}
</style>
