<template>
  <div class="groupcharter-container bg-waves" style="background-position: center 35%">
    <div class="groupcharter">
      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public/images/hero-banner-butn.png" alt="Plane" /> -->
        <h4>GROUP CHARTER SERVICES</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          FLY TOGETHER IN LUXURY
        </h1>
        <p class="white--text" style="margin-top: 2.5rem">
          Whether you’re planning a corporate retreat, a destination wedding, or
          a special group vacation, C&C Private Jets ensures your group travels
          in unparalleled comfort and style. Our group charter services are
          designed to make your collective journey as seamless and enjoyable as
          possible.
        </p>

        <button class="button" @click="dialog = true">Enquire Now</button>
      </div>
    </div>
    <div class="groupcharter-line">
      <v-img src="../../public/images/groupcharter/plane.png"></v-img>
    </div>
    <div class="heading" style="margin-top: 4rem">
      <div class="eclipse"></div>
      <h2 class="mb-8">WHY CHOOSE US FOR GROUP TRAVEL</h2>
    </div>
    <div class="slider">
      <carousel-3d
        :perspective="0"
        :space="500"
        :display="3"
        :width="700"
        :autoplay="true"
        :autoplay-timeout="3000"
        :height="600"
        @after-slide-change="handleSlideChange"
      >
        <slide :index="0">
          <div @click="handleIndex('0')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/groupcharter/simage2.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>
              <div class="side-content" v-if="activeIndex === 0">
                <div>
                  <h3>Customized</h3>
                  <h3>Experience</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  Every group flight is fully customizable to meet your specific
                  needs and desires. From arranging the seating configuration to
                  catering preferences and onboard entertainment, every detail
                  is tailored to enhance your group’s comfort and enjoyment.
                </p>
              </div>
            </div>
          </div>
        </slide>
        <slide :index="1">
          <div @click="handleIndex('1')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/groupcharter/simage3.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>
              <div class="side-content" v-if="activeIndex === 1">
                <div>
                  <h3>Dedicated</h3>
                  <h3>Support</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  Our experienced team is dedicated to managing all aspects of
                  your group travel. From the moment you book with us until you
                  return home, we provide a smooth, hassle-free experience. Our
                  concierge service can help organize not only your flight but
                  also any additional arrangements like hotel bookings, ground
                  transportation, and event tickets.
                </p>
              </div>
            </div>
          </div>
        </slide>
        <slide :index="2">
          <div @click="handleIndex('2')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/groupcharter/simage1.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>

              <div class="side-content" v-if="activeIndex === 2">
                <div>
                  <h3>Privacy &</h3>
                  <h3>Convenience</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  Skip the long lines and crowded commercial flights. Our
                  private terminals offer you and your group privacy and direct
                  boarding, significantly reducing travel time and stress.
                </p>
              </div>
            </div>
          </div>
        </slide>
      </carousel-3d>
    </div>
    <div class="heading" style="margin-top: 8rem">
      <h2 class="mb-8">OUR CRAFTS FOR GROUP TRAVEL</h2>
      <p>
        Our fleet includes a range of aircraft suitable for group travel,
        ensuring that no matter the size of your party, we
      </p>
      <p>have the perfect jet to accommodate everyone comfortably.</p>
    </div>
    <div class="our-jets1">
      <div>
        <h2>MID-SIZE JETS</h2>
        <p>
          Ideal for smaller groups, offering a balance of comfort and range,
          perfect for both short and long-haul flights.
        </p>
      </div>
    </div>
    <div class="our-jets2">
      <div>
        <h2>HEAVY JETS</h2>
        <p>
          Best suited for larger groups, these jets offer spacious cabins,
          luxury seating, and are equipped with amenities like full galleys and
          conference facilities for in-flight meetings.
        </p>
      </div>
    </div>
    <div class="our-jets3">
      <div>
        <h2>VIP AIRLINES</h2>
        <p>
          For the ultimate in group travel, our VIP airliners can carry larger
          groups with the epitome of luxury and space, featuring lounge areas,
          private suites, and more.
        </p>
      </div>
    </div>
    <div class="heading" style="margin-top: 8rem">
      <h2 class="mb-8">OUR SERVICES FOR GROUPS</h2>
    </div>
    <v-row class="groupservices" justify="center">
      <v-col class="box" style="text-align: -webkit-center;" cols="12" md="3" sm="12">
        <v-img
          src="../../public/images/groupcharter/catering.png"
          max-width="60"
          class="icon-image"
        ></v-img>
        <h4>BESPOKE CATERING</h4>
        <p>
          Enjoy meals curated to your group’s tastes, prepared by top chefs,
          ensuring a dining experience that rivals the finest restaurants.
        </p>
      </v-col>
      <v-col class="box" style="text-align: -webkit-center;" cols="12" md="3" sm="12">
        <v-img
          src="../../public/images/groupcharter/wifi.png"
          max-width="60"
          class="icon-image"
        ></v-img>
        <h4>ONBOARD CONNECTIVITY</h4>
        <p>
          Stay connected with high-speed Wi-Fi and satellite communications,
          allowing for both work and entertainment.
        </p>
      </v-col>
      <v-col class="box" style="text-align: -webkit-center;" cols="12" md="3" sm="12">
        <v-img
          src="../../public/images/groupcharter/schedule.png"
          max-width="60"
          class="icon-image"
        ></v-img>
        <h4>EVENT COORDINATION</h4>
        <p>
          Planning an event? Let our team assist with logistics, from on-ground
          arrangements to special celebrations onboard.
        </p>
      </v-col>
    </v-row>
    <div class="booking">
      <div class="imgcontainer">
<!--        <div class="blur-box">-->
<!--          &lt;!&ndash; <v-img src="../../public/images/groupcharter/dull.png"></v-img> &ndash;&gt;-->
<!--        </div>-->
        <div class="text-box">
          <h2>
            BOOK YOUR GROUP <br />
            CHARTER
          </h2>
          <p>
            Start planning your group’s journey today. Contact our charter
            specialists who are ready to help design your perfect travel plan,
            offering a personalized quote and answering any questions you may
            have.
          </p>
          <button class="button mt-4" @click="dialog = true">Contact Now</button>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterComponent />
    <!------------Form dialog-------------------->

    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      activeIndex: 0,
      dialog: false,
    };
  },
  methods: {
    handleSlideChange(event) {
      console.log(event); // Log the entire event object to inspect it
      this.activeIndex = +event.index || +event.slideIndex || +event; // Try different ways to access the index
      console.log("Active slide index:", +this.activeIndex);
    },
    handleIndex(index) {
      console.log(index);
    },
    close() {
      this.dialog = false;
    },
  },
  metaInfo () {
    return {
      title: `Group charter - C&C Private Jets`,
      meta: [
        {
          name: 'description',
          content:  "C&C Private Jets offers luxury group charter services for corporate retreats, destination weddings, and group vacations. Travel in comfort and style with a seamless experience."
        }
      ]
    }
  },
};
</script>

<style></style>
