<template>
  <div class="footer">
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <img
          class="logo"
          src="../../public../../public/images/logo.png"
          alt=""
        />
        <p class="my-4 logo-text">
          C&C Private Jets is an air charter broker acting as agent for its
          customers when arranging air transportation services. C&C Private Jets
          is not a direct or indirect air carrier or foreign air carrier. All
          air transportation arranged by C&C private Jets is provided by
          properly licensed direct air carriers or direct foreign air carriers.
        </p>
        <div>
          <div class="d-flex align-center justify-start directed footer-social-icons">
            <div class="social-icons">
              <a target="_blank" href="https://www.instagram.com/ccprivatejets?igsh=dG54amx4djltYXZt">
                <i class="fab fa-instagram" style="color: white"></i>
              </a>
            </div>
            <div class="social-icons mx-5">
              <a target="_blank" href="https://www.facebook.com/profile.php?id=61563399845518&mibextid=LQQJ4d">
                <i class="fab fa-facebook-f" style="color: white"></i>
              </a>
            </div>
<!--            <div class="social-icons">-->
<!--              <i class="fab fa-tiktok"></i>-->
<!--            </div>-->
            <div class="social-icons">
              <a target="_blank" href="https://www.linkedin.com/company/c-c-private-jets/">
                <i class="fab fa-linkedin-in" style="color: white"></i>
              </a>
            </div>
          </div>
        </div>
      </v-col>
      <v-col sm="6" md="3" lg="2">
        <div class="d-flex justify-center">
          <div>
            <div class="heading1">COMPANY</div>
            <div class="frame-26088205">
              <div class="text">
                <a href="/"> <p>Home</p></a>
              </div>
              <div class="text my-3">
                <router-link to="/aboutus" active-class="active-p">
                  <p>About us</p>
                </router-link>
              </div>
              <div class="text">
                <router-link to="/fleet" active-class="active-p">
                  <p>Fleet</p>
                </router-link>
              </div>
              <div class="text my-3">
                <router-link to="/whyus" active-class="active-p">
                  <p>Why us</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col sm="6" md="3" lg="2">
        <div class="d-flex justify-center">
          <div>
            <div class="heading1">HELP</div>
            <div class="frame-26088205">
              <router-link to="/terms" active-class="active-p">
                <p>Terms and Conditions</p>
              </router-link>
              <router-link to="/privacy" active-class="active-p">
                <p>Privacy Policy</p>
              </router-link>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <div class="heading1">SUBSCRIBE TO NEWSLETTER</div>
        <input type="text" class="text-feild" placeholder="Enter your email" />
        <div>
          <button class="subscribeBtn">Subscribe</button>
        </div>
      </v-col>
    </v-row>
    <v-divider style="border-color: #3e4550" class="my-8"></v-divider>
    <div class="d-flex justify-center white--text">
      <!-- <p style="color: #fbbd4e; margin-right: 6px; margin-top: 1rem"></p> -->
      © Copyright 2024, All Rights Reserved by C&C Private Jets. Developed by
      <a href="https://redpanda.network" target="_blank" class="white--text ml-1" > Red Panda Network. </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style></style>
