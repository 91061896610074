<template>
  <div class="yacht-container" style="background-image: url(images/superyacht/bg-waves.png);">
    <div class="superyacht">
      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public/images/hero-banner-butn.png" alt="Plane" /> -->
        <h4>SUPERYACHT CHARTER SERVICES</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          EMBARK ON A VOYAGE OF LUXURY
        </h1>
        <h1
          class="mb-0 pa-0 mt-5"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          AND DISCOVERY
        </h1>
        <p class="white--text" style="margin-top: 2.5rem">
          Discover the epitome of marine elegance with C&C Private Jets'
          superyacht charter services. Our bespoke yacht charters offer
          unparalleled luxury, privacy, and freedom to explore the world’s most
          breathtaking coastal destinations. Whether you're seeking a serene
          escape or an extravagant adventure, our fleet of majestic superyachts
          promises an unforgettable journey.
        </p>
    
        <button class="button" @click="dialog = true">Enquire Now</button>
      </div>
    </div>
    <div class="yacht">
      <v-img src="../../public/images/superyacht/yacht-arrow.png"></v-img>
    </div>
    <div class="heading">
      <div class="eclipse"></div>
      <h2>WHY CHOOSE C&C PRIVATE JETS FOR</h2>
      <h2>YOUR SUPERYACHT EXPERIENCE?</h2>
    </div>
    <div class="slider">
      <carousel-3d
        :perspective="0"
        :space="500"
        :display="3"
        :width="700"
        :autoplay="true"
        :autoplay-timeout="3000"
        :height="700"
        @after-slide-change="handleSlideChange"
      >
        <slide :index="0">
          <div @click="handleIndex('0')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/superyacht/image2.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>
              <div class="side-content" v-if="activeIndex === 0">
                <div>
                  <h3>Exquisite Comfort</h3>
                  <h3>& Style</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  Our fleet features some of the most prestigious yachts in the
                  world, each crafted with opulent interiors, state-of-the-art
                  technology, and sumptuous accommodations. From expansive decks
                  perfect for sunbathing to elegant dining areas and private
                  jacuzzis, every detail is designed to offer supreme comfort
                  and luxury.
                </p>
              </div>
            </div>
          </div>
        </slide>
        <slide :index="1">
          <div @click="handleIndex('1')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/superyacht/image3.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>
              <div class="side-content" v-if="activeIndex === 1">
                <div>
                  <h3>Tailored</h3>
                  <h3>Itineraries</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  Each charter is custom-designed to fit your personal desires
                  and sailing preferences. Whether you wish to explore secluded
                  islands, dive into crystal-clear waters, or host a glamorous
                  yacht party, our dedicated team will curate a personalized
                  itinerary that perfectly aligns with your aspirations.
                </p>
              </div>
            </div>
          </div>
        </slide>
        <slide :index="2">
          <div @click="handleIndex('2')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/superyacht/image1.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>

              <div class="side-content" v-if="activeIndex === 2">
                <div>
                  <h3>World-Class</h3>
                  <h3>Service</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  Our highly trained crew, including gourmet chefs, skilled
                  stewards, and knowledgeable captains, are dedicated to
                  providing impeccable service and ensuring every aspect of your
                  journey is seamless. Your privacy, comfort, and satisfaction
                  are our utmost priorities.
                </p>
              </div>
            </div>
          </div>
        </slide>
      </carousel-3d>
    </div>
    <div class="performance">
      <div class="heading">
        <h4>OUR FLEET</h4>
        <h2>A SELECTION OF MAGNIFICENCE</h2>
      </div>
      <div class="line">
        <v-img src="../../public/images/superyacht/yellow-line.png"></v-img>
      </div>
      <p>
        C&C Private Jets offers a diverse range of superyachts accommodating
        various guest capacities and
      </p>
      <p>
        preferences. Each yacht is a masterpiece of engineering and design,
        equipped with luxurious amenities such as:
      </p>
      <v-row class="advantage-container">
        <v-col cols="3" class="d-flex justify-space-between align-center">
          <div class="d-flex justify-center flex-column align-center">
            <v-img
              src="../../public/images/superyacht/cinema.png"
              max-width="100"
              class="mb-6 icon-image"
            ></v-img>
            <h4 style="text-align: center">ONBOARD CINEMA</h4>
            <h4 style="text-align: center">AND SPAS</h4>
          </div>
          <div class="xs-hide">
            <v-img src="../../public/images/superyacht/vertical.png"></v-img>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex justify-center align-center">
          <div class="d-flex justify-center flex-column align-center">
            <v-img
              src="../../public/images/superyacht/gym.png"
              max-width="100"
              class="mb-6 icon-image"
            ></v-img>
            <h4 style="text-align: center">STATE-OF-THE-ART</h4>
            <h4 style="text-align: center">GYMS</h4>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex justify-space-between align-center">
          <div class="xs-hide">
            <v-img src="../../public/images/superyacht/vertical.png"></v-img>
          </div>
          <div class="d-flex justify-center flex-column align-center">
            <v-img
              src="../../public/images/superyacht/gear.png"
              max-width="100"
              class="mb-6 icon-image"
            ></v-img>
            <h4 style="text-align: center">FULLY EQUIPPED</h4>
            <h4 style="text-align: center">WATER SPORTS GEAR</h4>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex justify-space-between align-center">
          <div class="xs-hide">
            <v-img src="../../public/images/superyacht/vertical.png"></v-img>
          </div>
          <div class="d-flex justify-center flex-column align-center">
            <v-img
              src="../../public/images/superyacht/h.png"
              max-width="100"
              class="mb-6 icon-image"
            ></v-img>
            <h4 style="text-align: center">HELIPADS ON SELECT</h4>
            <h4 style="text-align: center">VESSELS</h4>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="enhanced-experience">
      <div class="heading mb-12">
        <h4>ENHANCED EXPERIENCES</h4>
        <h2>BEYOND SAILING</h2>
      </div>

      <div class="contant-container">
        <div class="image-container-left">
          <div>
            <v-img
              src="../../public/images/superyacht/dining.png"
              max-height="100%"
              max-width="100%"
            ></v-img>
          </div>
          <div class="blur-image">
            <h1>Gourmet Dining</h1>
            <svg
              width="510"
              height="1"
              class="mb-9 mt-4"
              viewBox="0 0 510 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                opacity="0.4"
                x1="0.5"
                y1="0.5"
                x2="509.5"
                y2="0.5"
                stroke="url(#paint0_linear_2316_6596)"
                stroke-linecap="round"
                stroke-dasharray="4 4"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2316_6596"
                  x1="0"
                  y1="1"
                  x2="0.571783"
                  y2="18.3162"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FBBD4D" />
                  <stop offset="1" stop-color="#FFD68C" />
                </linearGradient>
              </defs>
            </svg>
            <p class="des">
              Enjoy bespoke menus crafted by onboard chefs, featuring local and
              international cuisines.
            </p>
          </div>
        </div>
        <div class="d-flex justify-end">
          <div class="image-container-right">
            <div>
              <v-img
                src="../../public/images/superyacht/adventure.png"
                max-height="100%"
                max-width="100%"
              ></v-img>
            </div>
            <div class="blur-image">
              <h1>Adventure and Leisure</h1>
              <svg
                width="510"
                height="1"
                class="mb-9 mt-4"
                viewBox="0 0 510 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  opacity="0.4"
                  x1="0.5"
                  y1="0.5"
                  x2="509.5"
                  y2="0.5"
                  stroke="url(#paint0_linear_2316_6596)"
                  stroke-linecap="round"
                  stroke-dasharray="4 4"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2316_6596"
                    x1="0"
                    y1="1"
                    x2="0.571783"
                    y2="18.3162"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FBBD4D" />
                    <stop offset="1" stop-color="#FFD68C" />
                  </linearGradient>
                </defs>
              </svg>
              <p class="des">
                Engage in water sports, diving excursions, or wellness retreats,
                all arranged to your liking.
              </p>
            </div>
          </div>
        </div>
        <div class="image-container-left">
          <div>
            <v-img
              src="../../public/images/superyacht/event.png"
              max-height="100%"
              max-width="100%"
            ></v-img>
          </div>
          <div class="blur-image">
            <h1>Event Hosting</h1>
            <svg
              width="510"
              height="1"
              class="mb-9 mt-4"
              viewBox="0 0 510 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                opacity="0.4"
                x1="0.5"
                y1="0.5"
                x2="509.5"
                y2="0.5"
                stroke="url(#paint0_linear_2316_6596)"
                stroke-linecap="round"
                stroke-dasharray="4 4"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2316_6596"
                  x1="0"
                  y1="1"
                  x2="0.571783"
                  y2="18.3162"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FBBD4D" />
                  <stop offset="1" stop-color="#FFD68C" />
                </linearGradient>
              </defs>
            </svg>
            <p class="des">
              Our yachts serve as perfect venues for hosting corporate events,
              family reunions, or spectacular social gatherings.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="booking">
      <div class="imgcontainer">
<!--        <div class="blur-box">-->
<!--          &lt;!&ndash; <v-img src="../../public/images/superyacht/dull-yacht.png"></v-img> &ndash;&gt;-->
<!--        </div>-->
        <div class="text-box">
          <h3>BOOK WITH CHARTER</h3>
          <h2>SET SAIL IN LUXURY</h2>
          <p>
            Start planning your luxury superyacht adventure with C&C Private
            Jets today. Contact our dedicated charter specialists who are ready
            to help you design your perfect sea journey, offering expert advice
            and personalized service. With C&C Private Jets, your dream voyage
            is just a horizon away.
          </p>
          <button class="button mt-4"  @click="dialog = true">Contact Now</button>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterComponent />
    <!------------Form dialog-------------------->

    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      activeIndex: 0,
      dialog: false,
    };
  },
  methods: {
    handleSlideChange(event) {
      //console.log(event); // Log the entire event object to inspect it
      this.activeIndex = +event.index || +event.slideIndex || +event; // Try different ways to access the index
      //console.log("Active slide index:", +this.activeIndex);
    },
    handleIndex(index) {
      //console.log(index);
    },
    close() {
      this.dialog = false;
    },
  },
  metaInfo () {
    return {
      title: `Superyacht - C&C Private Jets`,
      meta: [
        {
          name: 'description',
          content:  "Experience luxury and discovery with C&C Private Jets' superyacht charter services. Enjoy unparalleled elegance, privacy, and freedom to explore stunning coastal destinations aboard our majestic superyachts."
        }
      ]
    }
  },
};
</script>

<style></style>
