<template>
  <div class="thirdpartyform">
      <!-- include this script -->
      <script type="module" src="https://candcprivatejets-09bdb62cef6d.herokuapp.com/public/scripts/webform.js"></script>

      <!-- Place this div where you want the form to appear: -->
      <div id="embeddable-form-container"></div>
  </div>
</template>

<script>
export default {};
</script>
