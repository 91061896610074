<template>
  <div class="whyus-container bg-waves">
    <div class="whyus">
      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public/images/hero-banner-butn.png" alt="Plane" /> -->
        <h4>WHY CHOOSE C&C PRIVATE JETS?</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          YOUR PREMIER PARTNER IN
        </h1>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          PRIVATE AVIATION
        </h1>
        <p class="white--text" style="margin-top: 2.5rem">
          At C&C Private Jets, we understand that choosing a private jet service
          is about more than just finding a flight—it's about ensuring a
          seamless, luxurious, and personalized travel experience. Here are the
          reasons why partnering with us makes all the difference.
        </p>
      </div>
    </div>
    <div class="aircraft-line">
      <v-img src="../../public/images/aircraft/plane.png"></v-img>
    </div>
    <div class="section1">
      <div class="blur-container">
        <h4>UNMATCHED EXPERTISE</h4>
        <h2>DECADES OF INDUSTRY EXPERIENCE</h2>

        <p>
          With over 30 years in the business and aviation sectors, our team
          brings unmatched expertise to every aspect of your journey. We know
          the ins and outs of the aviation world, which allows us to provide not
          only flights but comprehensive travel solutions that meet the highest
          standards of safety, comfort, and efficiency.
        </p>
      </div>
      <v-img
        src="../../public/images/whyus/sec1-image.png"
        max-width="680"
      ></v-img>
    </div>

    <div class="eclipse" style="right: 0px"></div>
    <div class="section2">
      <div>
        <h4 class="text-center">EXTENSIVE GLOBAL NETWORK</h4>
        <h2 class="text-center">ACCESS TO A DIVERSE FLEET</h2>
        <p class="text-center mt-4" style="padding: 0px 50px; line-height:30px">
          As a broker, we have established relationships with numerous top-tier
          operators
          worldwide. This extensive network enables us to offer you a wide range
          of aircraft options
        
          from light jets for short hops to opulent airliners for
          intercontinental voyages, ensuring you
        always have the perfect jet for your needs.</p>
      </div>
    </div>
    <div class="section3">
      <div class="grid-container">
        <div class="grid-item">
          <v-img src="../../public/images/whyus/cimg1.png"></v-img>
        </div>
        <div class="grid-item">
          <div>
            <h3>Tailored Travel Experiences</h3>
            <h2>Customized Down to the Last Detail</h2>
            <p class="mt-11">
              Every trip with C&C Private Jets is tailor-made. We take the time
              to understand your preferences, needs, and desires to craft a
              personalized travel experience. Whether you need a pet-friendly
              cabin, specific gourmet meals, or on-ground arrangements, we
              handle it all with impeccable attention to detail.
            </p>
          </div>
        </div>
      </div>
      <div class="grid-container">
        <div class="eclipse"></div>
        <div class="grid-item reverse">
          <div>
            <h3>Dedicated Client Service</h3>
            <h2>24/7 Personal Concierge Support</h2>
            <p class="mt-11">
              Our commitment to your satisfaction is reflected in our 24/7
              concierge service. From the moment you contact us until you return
              home, our dedicated team is here to assist you with every detail,
              ensuring a hassle-free experience. Our clients enjoy peace of mind
              knowing that expert support is just a phone call away.
            </p>
          </div>
        </div>
        <div class="grid-item">
          <v-img src="../../public/images/whyus/cimg2.png"></v-img>
        </div>
      </div>
      <div class="grid-container">
        <div class="grid-item">
          <v-img src="../../public/images/whyus/cimg3.png"></v-img>
        </div>
        <div class="grid-item">
          <div>
            <h3>Safety and Security</h3>
            <h2>Commitment to the Highest Standards</h2>
            <p class="mt-11">
              Safety is paramount at C&C Private Jets. We only work with
              operators who meet stringent safety criteria and are often ARGUS
              certified or Wyvern approved. Our rigorous selection process
              guarantees that every jet in our network is maintained and
              operated according to the highest industry standards.
            </p>
          </div>
        </div>
      </div>
      <div class="grid-container">

        <div class="eclipse"></div>
        <div class="grid-item reverse">
          <div>
            <h3>Transparent and Competitive Pricing</h3>
            <h2>No Hidden Fees, Just Clear Value</h2>
            <p class="mt-11">
              We believe in transparency. With C&C Private Jets, there are no
              hidden fees or surprises. We provide clear, upfront pricing and
              strive to offer competitive rates that reflect the true value of
              the services provided, ensuring you receive the best possible
              experience at a fair price.
            </p>
          </div>
        </div>
        <div class="grid-item">
          <v-img src="../../public/images/whyus/cimg4.png"></v-img>
        </div>
      </div>
    </div>
    <div class="booking">
      <div class="imgcontainer">
<!--        <div class="blur-box">-->
<!--          &lt;!&ndash; <v-img src="../../public/images/fleet/plane-blur.png"></v-img> &ndash;&gt;-->
<!--        </div>-->
        <div class="text-box">
          <h3>Experience the C&C Private Jets Difference</h3>
          <h2>Book Your Journey</h2>
          <h2>Today</h2>
          <p>
            Discover why discerning travelers choose C&C Private Jets for their
            private aviation needs. Contact us today to plan your next journey
            and experience the ultimate in luxury travel, tailored precisely to
            your specifications.
          </p>
          <button class="button mt-4" @click="dialog = true">
            Contact Now
          </button>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterComponent />

    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
  metaInfo () {
    return {
      title: `Why us - C&C Private Jets`,
      meta: [
        {
          name: 'description',
          content:  "Discover why C&C Private Jets is your premier partner in private aviation. We offer a seamless, luxurious, and personalized travel experience tailored to your needs."
        }
      ]
    }
  },
};
</script>

<style></style>
