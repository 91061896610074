<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  metaInfo: {
    title: 'C&C Private Jets'
  },
  head: {
    link: [
      {
        rel: "icon",
        href: require("../public/img/icons/favicon.svg")
      },
    ]
  },
  data: () => ({
    //
  }),
};
</script>
