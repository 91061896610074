<template>
  <div class="charter-services" style="background-image: url('images/charters/charters.png')">
    <div class="charters">

      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public../../public/images/hero-banner-butn.png" alt="Plane" /> -->
        <h4>HELICOPTER CHARTER SERVICES</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          TAILORED HELICOPTER TRAVEL WITH UNRIVALED FLEXIBILITY
        </h1>
        <p class="white--text" style="margin-top: 2.5rem">
          At C&C Private Jets, we specialize in arranging bespoke helicopter
          charters that offer ultimate convenience and luxury. Whether
          navigating to secluded locations or simply escaping urban congestion,
          our helicopter services provide direct access to the most remote or
          crowded destinations
        </p>
        
        <button class="button" @click="dialog = true">Enquire Now</button>
      </div>
    </div>
    <div class="plane">
      <v-img src="../../public/images/charters/Group.png"></v-img>
    </div>
    <div class="heading">
      <h2>WHY PARTNER WITH C&C PRIVATE JETS</h2>
      <h2>FOR YOUR HELICOPTER CHARTER?</h2>
    </div>
    <div class="slider">
      <carousel-3d
        :perspective="0"
        :space="500"
        :display="3"
        :width="700"
        :autoplay="true"
        :autoplay-timeout="3000"
        :height="600"
        @after-slide-change="handleSlideChange"
      >
        <slide :index="0">
          <div @click="handleIndex('0')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/charters/image2.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>
              <div class="side-content" v-if="activeIndex === 0">
                <div>
                  <h3>SEAMLESS</h3>
                  <h3>ACCESSIBILITY</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  Leverage our expertise to access helipads, private properties,
                  and exclusive resorts without the constraints of traditional
                  airports. C&C Private Jets ensures you land closer to your
                  destination, saving you time and hassle.
                </p>
              </div>
            </div>
          </div>
        </slide>
        <slide :index="1">
          <div @click="handleIndex('1')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/charters/image3.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>
              <div class="side-content" v-if="activeIndex === 1">
                <div>
                  <h3>EFFICIENT &</h3>
                  <h3>FAST</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  Maximize your day with rapid departures and direct routes.
                  Ideal for business itineraries requiring quick site visits or
                  leisure escapes to distant retreats, our helicopter charters
                  enhance your time efficiency.
                </p>
              </div>
            </div>
          </div>
        </slide>
        <slide :index="2">
          <div @click="handleIndex('2')">
            <div class="d-flex">
              <div>
                <img
                  src="/images/charters/image1.png"
                  style="max-width: 100%; min-height: 424px"
                />
              </div>

              <div class="side-content" v-if="activeIndex === 2">
                <div>
                  <h3>SPECTACULAR</h3>
                  <h3>VIEWS</h3>
                </div>
                <img src="/images/charters/Line.png" class="my-3" />
                <p class="mr-4">
                  Elevate special occasions with stunning aerial vistas. From
                  scenic city tours to breathtaking coastal flights, each
                  charter with C&C Private Jets is an opportunity to experience
                  unparalleled views.
                </p>
              </div>
            </div>
          </div>
        </slide>
      </carousel-3d>
    </div>
    <div class="heading">
      <h2>DIVERSE CHARTER OPTIONS</h2>
    </div>
    <div>
      <div class="diversecharter-right">
        <div class="img">
          <v-img
            src="../../public/images/charters/diverse1.png"
            class="mainImage"
          ></v-img>
          <v-img
            src="../../public/images/charters/logo1.png"
            class="logo"
          ></v-img>
        </div>
        <div class="text-box">
          <h3>BUSINESS SOLUTIONS</h3>
          <div style="position: relative">
            <v-img
              src="../../public/images/charters/orange-line.png"
              class="line"
            ></v-img>
          </div>
          <p
            >Optimize travel schedules and reach multiple destinations in a
            single day.</p
          >
        </div>
      </div>
      <div class="diversecharter-left">
        <div class="text-box">
          <h3>LEISURE AND LUXURY</h3>
          <div style="position: relative">
            <v-img
              src="../../public/images/charters/orange-line.png"
              class="line"
            ></v-img>
          </div>
          <p
            >Escape to exclusive locales or arrange a special scenic flight that
            promises unforgettable memories.</p
          >
        </div>
        <div class="img">
          <v-img
            src="../../public/images/charters/diverse2.png"
            class="mainImage"
          ></v-img>
          <v-img
            src="../../public/images/charters/logo2.png"
            class="logo"
          ></v-img>
        </div>
      </div>
      <div class="diversecharter-right">
        <div class="img">
          <v-img
            src="../../public/images/charters/diverse3.png"
            class="mainImage"
          ></v-img>
          <v-img
            src="../../public/images/charters/logo3.png"
            class="logo"
          ></v-img>
        </div>
        <div class="text-box">
          <h3>EVENT TRAVEL</h3>
          <div style="position: relative">
            <v-img
              src="../../public/images/charters/orange-line.png"
              class="line"
            ></v-img>
          </div>
          <p
            >Add a touch of grandeur to weddings, anniversaries, or milestone
            celebrations with stylish entrances and departures.</p
          >
        </div>
      </div>
      <div class="diversecharter-left">
        <div class="text-box">
          <h3>CUSTOM TOURS</h3>
          <div style="position: relative">
            <v-img
              src="../../public/images/charters/orange-line.png"
              class="line"
            ></v-img>
          </div>
          <p
            >Explore landmarks and natural beauties from an exhilarating new
            perspective.</p
          >
        </div>
        <div class="img">
          <v-img
            src="../../public/images/charters/diverse4.png"
            class="mainImage"
          ></v-img>
          <v-img
            src="../../public/images/charters/logo4.png"
            class="logo"
          ></v-img>
        </div>
      </div>
    </div>
    <div class="ourexpertise">
      <v-img class="image-expertise" src="../../public/images/charters/circle.png"></v-img>
      <div class="text-box">
        <h2>OUR EXPERTISE</h2>
        <p>
          Through our extensive network, C&C Private Jets offers access to
          top-tier helicopters, each equipped with luxury amenities for comfort
          and style. Safety and performance are our priorities, with each
          aircraft operated by highly skilled pilots and maintained beyond
          regulatory standards.
        </p>
      </div>
    </div>
    <div class="journeys">
      <div class="text-box">
        <h2>BESPOKE JOURNEYS</h2>
        <h3>CRAFTING YOUR PERFECT FLIGHT PLAN</h3>
        <v-img
          src="../../public/images/charters/orange-line.png"
          class="line"
        ></v-img>
        <p>
          Our team at C&C Private Jets excels in customizing experiences to fit
          your specific requirements. We handle all logistics, from itinerary
          planning to on-ground arrangements, ensuring your helicopter charter
          is perfectly synchronized with your broader travel plans.
        </p>
      </div>
      <v-img src="../../public/images/charters/journeys.png"></v-img>
    </div>
    <div class="booking">
      <div class="imgcontainer">
<!--        <div class="blur-box">-->
<!--          &lt;!&ndash; <v-img src="../../public/images/charters/dull.png"></v-img> &ndash;&gt;-->
<!--        </div>-->
        <div class="text-box">
          <h3>BOOK WITH CONFIDENCE</h3>
          <h2>START YOUR JOURNEY</h2>
          <p>
            Connect with us at C&C Private Jets to discuss your helicopter
            charter needs. Our consultants are adept at designing personalized
            air travel experiences that prioritize your convenience, safety, and
            satisfaction. Elevate your travel expectations with C&C Private
            Jets, where no destination is out of reach.
          </p>
          <button class="button" @click="dialog = true">Contact Now</button>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterComponent />

    <!------------Form dialog-------------------->

    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      activeIndex: 0,
      dialog: false,
    };
  },
  methods: {
    handleSlideChange(event) {
      //console.log(event); // Log the entire event object to inspect it
      this.activeIndex = +event.index || +event.slideIndex || +event; // Try different ways to access the index
      //console.log("Active slide index:", +this.activeIndex);
    },
    handleIndex(index) {
      //console.log(index);
    },
    close() {
      this.dialog = false;
    },
  },
  metaInfo () {
    return {
      title: `Helicopter charter - C&C Private Jets`,
      meta: [
        {
          name: 'description',
          content:  "C&C Private Jets offers tailored helicopter charter services for luxury travel. Access remote locations or avoid urban congestion with unrivaled flexibility and convenience."
        }
      ]
    }
  },
};
</script>

<style lang="scss">
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  > div {
    opacity: 0;
    animation: fadeIn 0.4s ease-in-out forwards;
  }

  .side-content {
    animation-delay: 0.5s; // Adjust this delay to stagger the animation
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
