<template>
  <div class="safety-container bg-waves">
    <div class="safety">
      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public/images/hero-banner-butn.png" alt="Plane" /> -->
        <h4>COMMITMENT TO SAFETY</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          UNWAVERING SAFETY STANDARDS
        </h1>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          AT C&C PRIVATE JETS
        </h1>
        <p class="white--text" style="margin-top: 2.5rem">
          At C&C Private Jets, your safety is our highest priority. We adhere to
          the most rigorous safety standards in the industry, ensuring that
          every aspect of your travel experience is secure and comfortable. Our
          commitment to safety is reflected in our choice of operators,
          aircraft, and crew, all meeting the highest certification standards.
        </p>
      </div>
    </div>
    <div class="aircraft-line">
      <v-img src="../../public/images/safety/plane.png"></v-img>
    </div>
    <div class="section1">
      <div>
        <v-img
          src="../../public/images/safety/tyre.png"
          style="width: 30vw"
        ></v-img>
      </div>
      <div class="ml-6">
        <h4>WHY SAFETY IS PARAMOUNT</h4>
        <h2>TRUSTED CERTIFICATION</h2>
        <p>
          We partner exclusively with operators who are certified by respected
          aviation safety organizations, ARGUS and Wyvern. These certifications
          are recognized globally as symbols of safety and excellence in
          aviation operations.
        </p>
        <div class="person">
          <div class="eclipse"></div>
          <div style="text-align: -webkit-center;">
            <v-img class="icon-image"
              src="../../public/images/safety/person.png"
              max-width="60"
            ></v-img>
          </div>
          <div class="ml-4 mt-2">
            <h4>ARGUS CERTIFIED OPERATORS</h4>
            <p>
              ARGUS certification involves comprehensive audits that assess
              operational practices, flight operations, maintenance, and
              aircraft suitability. Only operators who meet stringent safety
              criteria achieve Platinum, Gold, or Silver ratings.
            </p>
          </div>
        </div>
        <v-img src="../../public/images/safety/line.png" class="mb-8"></v-img>
        <div class="setting">
          <div style="text-align: -webkit-center;">
            <v-img
            class="icon-image"
              src="../../public/images/safety/badge.png"
              max-width="60"
            ></v-img>
          </div>
          <div class="ml-4 mt-2">
            <h4>WYVERN WINGMAN CERTIFICATION</h4>
            <p>
              The Wyvern Wingman standard is one of the most prestigious
              recognitions in the industry. It involves rigorous assessments of
              operational and maintenance practices. Operators must pass ongoing
              evaluations to maintain their certified status.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="headings">
      <div class="eclipse"></div>
      <h4>OUR SAFETY PRACTICES</h4>
      <h1
        class="mb-0 pa-0"
        style="
          background: linear-gradient(to right, #fbbd4d, #ffd68c);
          -webkit-background-clip: text;
          color: transparent;
        "
      >
        CONTINUOUS MONITORING AND IMPROVEMENT
      </h1>
    </div>
    <v-row class="section2">
      <v-col class="section2-blur2">
        <v-img
          src="../../public/images/safety/notes.png"
          max-width="60"
          max-height="60"
          contain
        ></v-img>
        <h4>REGULAR AUDITS</h4>
        <p>
          We conduct regular audits of our operations and partners to ensure
          compliance with both international safety standards and our own
          rigorous criteria.
        </p>
      </v-col>
      <v-col class="section2-blur1">
        <v-img
          src="../../public/images/safety/helmet.png"
          max-width="60"
          max-height="60"
          contain
        ></v-img>
        <h4 class="my-1">PILOT TRAINING</h4>
        <p>
          Our pilots undergo extensive, continuous training exceeding industry
          requirements. This includes simulator training, emergency response
          drills, and the latest in-flight safety procedures.
        </p>
      </v-col>
      <v-col class="section2-blur3">
        <v-img
          src="../../public/images/safety/setting.png"
          max-width="60"
          max-height="60"
          contain
        ></v-img>
        <h4>AIRCRAFT MAINTENANCE</h4>
        <p>
          Each aircraft in our fleet is subject to strict maintenance protocols.
          Maintenance is performed by certified technicians and reviewed
          regularly to ensure compliance with the highest safety standards.
        </p>
      </v-col>
    </v-row>
    <div class="section3">
      <div class="mr-6">
        <h4 class="mb-5">ADVANCED TECHNOLOGY</h4>
        <h2>ENHANCING SAFETY</h2>
        <h2>THROUGH INNOVATION</h2>
        <div class="person mt-8">
          <div style="text-align: -webkit-center;">
            <v-img
              src="../../public/images/safety/navigation.png"
              max-width="60"
              class="icon-image"
            ></v-img>
          </div>
          <div class="ml-4 mt-2">
            <h4>CUTTING-EDGE NAVIGATION</h4>
            <p>
              We equip our aircraft with the latest navigation technology to
              ensure safe travel through various weather conditions and
              landscapes.
            </p>
          </div>
        </div>
        <v-img src="../../public/images/safety/line.png" class="mb-8"></v-img>
        <div class="setting">
          <div style="text-align: -webkit-center;">
            <v-img
              src="../../public/images/safety/badge.png"
              max-width="60"
              class="icon-image"
            ></v-img>
          </div>
          <div class="ml-4 mt-2">
            <h4>REAL-TIME MONITORING</h4>
            <p>
              Our operations center monitors each flight in real-time, providing
              immediate support and intervention if needed.
            </p>
          </div>
        </div>
      </div>
      <div>
        <v-img
          src="../../public/images/safety/pilot.png"
          style="width: 34vw"
        ></v-img>
      </div>
    </div>
    <div class="headings">
      <div class="eclipse"></div>
      <h4>COMMITMENT TO TRANSPARENCY</h4>
      <h1
        class="mb-0 pa-0"
        style="
          background: linear-gradient(to right, #fbbd4d, #ffd68c);
          -webkit-background-clip: text;
          color: transparent;
        "
      >
        OPEN AND HONEST COMMUNICATION
      </h1>
      <p class="white--text" style="margin-top: 2.5rem">
        We believe in transparency and open communication about our safety
        practices. Clients can
      </p>
      <p class="white--text">
        request detailed reports on our safety standards, pilot qualifications,
        and operational procedures at
      </p>
      <p class="white--text">any time.</p>
    </div>
    <div class="booking">
      <div class="imgcontainer">
<!--        <div class="blur-box">-->
<!--          &lt;!&ndash; <v-img src="../../public/images/safety/plane-blur.png"></v-img> &ndash;&gt;-->
<!--        </div>-->
        <div class="text-box">
          <h3>BOOK WITH CONFIDENCE</h3>
          <h2>Your Safety, Guaranteed</h2>
          <p>
            When you choose C&C Private Jets, you choose a partner committed to
            your safety and comfort. Contact us today to learn more about our
            safety practices, or to discuss any specific safety requirements you
            might have for your travel.
          </p>
          <button class="button mt-4" @click="dialog = true">Contact Now</button>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterComponent />
    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
  metaInfo () {
    return {
      title: `Safety - C&C Private Jets`,
      meta: [
        {
          name: 'description',
          content:  "C&C Private Jets prioritizes your safety with unwavering standards. We ensure secure and comfortable travel by partnering with certified operators, aircraft, and crew."
        }
      ]
    }
  },
};
</script>

<style></style>
