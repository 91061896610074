import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa", // You can use 'md' for Material Icons and 'fa' for Font Awesome
  },
});
