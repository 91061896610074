<template>
  <div class="fleet-container bg-waves">
    <div class="fleet">
      <!-- Navbar -->
      <HeaderComponent />
      <!-- Content -->
      <div class="content">
        <!-- <img src="../../public/images/hero-banner-butn.png" alt="Plane" /> -->
        <h4>OUR FLEET</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          ACCESS A WORLD-CLASS COLLECTION OF PRIVATE AIRCRAFT
        </h1>
        <p class="white--text" style="margin-top: 2.5rem">
          As your trusted private jet broker, C&C Private Jets offers access to
          an extensive and diverse range of aircraft to meet every travel need.
          Whether you’re looking for a nimble light jet for regional business
          trips or a luxurious airliner for global adventures, we connect you
          with the perfect jet to match your requirements and exceed your
          expectations.
        </p>
      </div>
    </div>
    <div class="aircraft-line">
      <v-img src="../../public/images/aircraft/plane.png"></v-img>
    </div>
    <div class="container-section">

        <div class="section1">
          <div class="d-flex align-start" style="position: relative">
            <v-img
              src="../../public/images/fleet/sec1.png"
              class="container-image xs-hide md-hide"
            ></v-img>
            <v-img
              src="../../public/images/fleet/sec1-small.png"
              max-width="200"
              class="ml-4 d-image xs-hide md-hide"
            ></v-img>
            <div class="blurimage-container">

              <div class="eclipse"></div>
              <div class="blur-images">
                <h4>POPULAR MODELS</h4>
                <p>Citation XLS, Learjet 70, Phenom 300</p>
              </div>
              <div class="blur-images light">
                <h4>FEATURES INCLUDE</h4>
                <p>
                  Plus leather seating, onboard Wi-Fi, advanced soundproofing, and
                  refreshment centers.
                </p>
              </div>
            </div>
          </div>
          <div class="section-content">
            <h4>Super Light Jets</h4>
            <h2>
              OPTIMAL EFFICIENCY <br />
              AND COMFORT
            </h2>
            <h2></h2>
            <v-img class="my-7" src="../../public/images/fleet/line.png"></v-img>
            <p>
              Ideal for quick getaways or short business trips, light jets provide
              a blend of speed, efficiency, and luxury. These aircraft are perfect
              for smaller groups looking to travel up to 2,500 miles with comfort
              and ease.
            </p>
          </div>
        </div>
        <div class="section2">
          <div class="section-content">
            <h4>Super midsize </h4>
            <h2>A PERFECT BALANCE</h2>
            <h2>AND COMFORT</h2>
            <v-img class="my-7" src="../../public/images/fleet/line.png"></v-img>
            <p>
              For travelers needing a bit more range and cabin space without
              committing to a larger jet, mid-size jets are an excellent choice.
              These aircraft offer enhanced comfort for medium-length flights and
              are equipped with amenities that make them ideal for relaxation or
              productivity.
            </p>
          </div>
          <div class="d-flex align-start" style="position: relative">
            <v-img
              src="../../public/images/fleet/sec2.png"
              class="container-image xs-hide md-hide"
            ></v-img>
            <v-img
              src="../../public/images/fleet/sec2-small.png"
              max-width="200"
              class="ml-4 d-image xs-hide md-hide"
            ></v-img>
            <div class="blurimage-container">

              <div class="eclipse"></div>
              <div class="blur-images">
                <h4>POPULAR MODELS</h4>
                <p>Hawker 800XP, Gulfstream G150, Challenger 350</p>
              </div>
              <div class="blur-images light">
                <h4>FEATURES INCLUDE</h4>
                <p>Stand-up cabins, in-flight entertainment systems, caterin, facilities, and private sleeping quarters.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="section3">
          <div class="d-flex align-start" style="position: relative">
            <v-img
              src="../../public/images/fleet/sec3.png"
              class="container-image xs-hide md-hide"
            ></v-img>
            <v-img
              src="../../public/images/fleet/sec3-small.png"
              max-width="200"
              class="ml-4 d-image xs-hide md-hide"
            ></v-img>
            <div class="blurimage-container">

              <div class="eclipse"></div>
              <div class="blur-images">
                <h4>POPULAR MODELS</h4>
                <p>Challenger 605, Falcon 2000 and Challenger 850</p>
              </div>
              <div class="blur-images light">
                <h4>FEATURES INCLUDE</h4>
                <p>Bedrooms, dining areas, luxury lounges, and full-service galleys.</p>
              </div>
            </div>
          </div>
          <div class="section-content">
            <h4>Heavy JETS</h4>
            <h2>ULTIMATE COMFORT</h2>
            <h2>FOR LONGER</h2>
            <h2>JOURNEYS</h2>
            <v-img class="my-7" src="../../public/images/fleet/line.png"></v-img>
            <p>
              Our heavy jets are the epitome of luxury and convenience for
              long-distance travel, featuring expansive interiors, high-end
              amenities, and capabilities for transcontinental flights.
            </p>
          </div>
        </div>
        <div class="section4">
          <div class="section-content">
            <h4>ULTRA LONG RANGE JETS</h4>
            <h2>
              FLY FURTHER WITH <br />
              UNMATCHED <br />
              ELEGANCE
            </h2>
            <v-img class="my-7" src="../../public/images/fleet/line.png"></v-img>
            <p>
              Ultra long range jets are designed for those who require non-stop
              travel across the globe. These jets combine long-distance
              capabilities with luxurious interiors, ensuring a restful and
              productive journey.
            </p>
          </div>
          <div class="d-flex align-start" style="position: relative">
            <v-img
              src="../../public/images/fleet/sec4.png"
              class="container-image xs-hide md-hide"
            ></v-img>
            <v-img
              src="../../public/images/fleet/sec4-small.png"
              max-width="200"
              class="ml-4 xs-hide md-hide"
            ></v-img>



            <div class="blurimage-container">

              <div class="eclipse"></div>
              <div class="blur-images">
                <h4>POPULAR MODELS</h4>
                <p>Bombardier Global 5500, Gulfstream G700, Gulfstream G650</p>
              </div>
              <div class="blur-images light">
                <h4>FEATURES INCLUDE</h4>
                <p>Ranges exceeding 5,500 miles, private offices, bespoke entertainment options, and gourmet dining experiences.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="section5">
          <div class="d-flex align-start" style="position: relative">
            <v-img
              src="../../public/images/fleet/sec5.png"
              class="container-image xs-hide md-hide"
            ></v-img>
            <v-img
              src="../../public/images/fleet/sec5-small.png"
              max-width="200"
              class="ml-4 d-image md-hide xs-hide"
            ></v-img>
            <div class="blurimage-container">
              <div class="blur-images">
                <h4>POPULAR MODELS</h4>
                <p>Boeing Business Jet, Airbus Corporate Jet</p>
              </div>
              <div class="blur-images light">
                <h4>FEATURES INCLUDE</h4>
                <p>Customizable floor plans, conference rooms, VIP suites, and state-of-the-art kitchens.</p>
              </div>
            </div>
          </div>
          <div class="section-content">
            <h4>VIP AIRLINERS</h4>
            <h2>BESPOKE</h2>
            <h2>CONFIGURATIONS</h2>
            <h2>FOR EXCEPTIONAL</h2>
            <h2>NEEDS</h2>
            <v-img class="my-7" src="../../public/images/fleet/line.png"></v-img>
            <p>
              For the ultimate in personalized air travel, our VIP airliners offer
              the space and flexibility of a private flying palace. Tailored to
              host everything from high-level business meetings to luxurious
              personal escapades, these airliners provide unparalleled space and
              amenities.
            </p>
          </div>
        </div>
        <div class="headings">
        <h4>OUR BROKERAGE ADVANTAGE</h4>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          EXPERTISE, FLEXIBILITY, AND
        </h1>
        <h1
          class="mb-0 pa-0"
          style="
            background: linear-gradient(to right, #fbbd4d, #ffd68c);
            -webkit-background-clip: text;
            color: transparent;
          "
        >
          UNMATCHED SERVICE
        </h1>
        <p class="white--text" style="margin-top: 2.5rem">
          As a leading jet brokerage, C&C Private Jets prioritizes your needs
          and preferences, offering
        </p>
        <p class="white--text">
          personalized service to ensure you find the perfect aircraft for your
          travels. Our access to a global
        </p>
        <p class="white--text">
          network of top-tier private jets ensures that you can always fly with
          confidence, enjoying the highest
        </p>
        <p class="white--text">standards of safety and luxury.</p>
      </div>
    </div>
    <div class="booking">
      <div class="imgcontainer">
<!--        <div class="blur-box">-->
<!--          &lt;!&ndash; <v-img src="../../public/images/fleet/plane-blur.png"></v-img> &ndash;&gt;-->
<!--        </div>-->
        <div class="text-box">
          <h3>EXPLORE OUR BROKERAGE SERVICES</h3>
          <h2>DISCOVER YOUR IDEAL</h2>
          <h2>AIRCRAFT</h2>
          <p>
            Contact C&C Private Jets today to discuss your aircraft needs. Our
            team is dedicated to sourcing the best jets that align with your
            travel preferences and requirements. Experience the freedom of
            flying exactly how you want, whenever you want.
          </p>
          <button class="button mt-4" @click="dialog = true">Contact Now</button>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterComponent />
    <v-dialog style='z-index:999999' v-model="dialog" width="80%" persistent>
      <v-card color="transparent">
        <ContactformComponent @close="close" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
  metaInfo () {
    return {
      title: `Fleet - C&C Private Jets`,
      meta: [
        {
          name: 'description',
          content:  "C&C Private Jets offers access to a world-class fleet of private aircraft. From light jets for business trips to luxurious airliners for global travel, we provide the perfect jet for your needs."
        }
      ]
    }
  },
};
</script>

<style></style>
