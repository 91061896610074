<template>
  <div class="planContainer">
    <div class="animation-container">
      <svg ref="svg" width="100%" height="100%" viewBox="0 0 1000 200">
        <!-- Define the wave path with 5 crests and 5 troughs -->
        <path
          id="flightPath"
          d="M0,250 Q100,150 200,250 T400,250 T600,250 T800,250 T1000,250 Q100,"
          fill="transparent"
          stroke="#363636"
          stroke-width="1"
          stroke-dasharray="5,5"
        />
        <!-- Plane image to be animated -->
        <image
          :x="planePosition.x"
          :y="planePosition.y"
          width="40"
          height="40"
          :href="require('../../public/images/airplane.png')"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      planePosition: { x: 0, y: 0 },
    };
  },
  mounted() {
    this.animatePlane();
  },
  methods: {
    animatePlane() {
      const path = this.$el.querySelector("#flightPath");
      const length = path.getTotalLength();
      const duration = 8000; // Duration of the animation in milliseconds

      const animate = (timestamp, startTime) => {
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);

        const point = path.getPointAtLength(progress * length);

        this.planePosition = { x: point.x - 20, y: point.y - 20 }; // Adjust to center the plane image

        if (progress < 1) {
          requestAnimationFrame((time) => animate(time, startTime));
        } else {
          // Restart the animation
          requestAnimationFrame((time) => animate(time, time));
        }
      };

      requestAnimationFrame((time) => animate(time, null));
    },
  },
};
</script>

<style scoped>
.planContainer {
  position: relative;
  height: 455px;
  overflow: hidden;
}
.animation-container {
  position: absolute;
  top: -135%;
  width: 100%;
  height: 1100px;
}
</style>
