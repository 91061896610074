import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/style/main.scss";
import HeaderComponent from "./components/header.component.vue";
import FooterComponent from "@/components/footer.component.vue";
import ContactformComponent from "@/components/contactform.component.vue";
import './registerServiceWorker'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false;

Vue.component("HeaderComponent", HeaderComponent);
Vue.component("FooterComponent", FooterComponent);
Vue.component("ContactformComponent", ContactformComponent);

Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");